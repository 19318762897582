import React, { useEffect, useState } from 'react';
import PaymentIcon from '@material-ui/icons/Payment';
import Table2, { DashboardTableHeaders } from '../../../Common/TableDashboard/TableDashboard';
import { RootState } from '../../../../../../redux/root';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ROUTES } from '../../../Routes/ClientRoutes';
import { PermitFeeItem } from '../../../../types';
import { setSelectedProjectInvoice } from '../../../../../../redux/slices/feesSlice';
import { setPermitInspections, setPermitInspectionsList } from '../../../../../../redux/slices/inspectionsSlice';

const FeesTable = () => {
  const history = useHistory();
  const clientProjecPermitList = useSelector((s: RootState) => s.fees.clientProjecPermitList);
  const [records, setRecords] = useState<PermitFeeItem[]>([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (clientProjecPermitList.length > 0) {
      const _items = clientProjecPermitList.reduce((feeArr, fee) => {
        fee.permits.forEach(permit => {
          const _item = {
            namePermitName: `${fee.projectName}/${permit.permitName}`,
            address: permit.permitAddress,
            permitId:permit.id
          };
          feeArr.push(_item);
        });
        return feeArr;
      }, []);
      setRecords(_items);
    }
  }, [clientProjecPermitList]);
  const onClick = (id: number) => {
    const _selected = clientProjecPermitList.find(r => r.permit.id === id);
    dispatch(setSelectedProjectInvoice(_selected));
    history.push(`${ROUTES.INVOICES}?openDrawer=true`);
  };
  return (
    <Table2 headers={DashboardTableHeaders('Fee')} records={records.slice(0, 5)} iconTitle="Pay fees" onClick={onClick}>
      <PaymentIcon />
    </Table2>
  );
};

export default FeesTable;
