import React from 'react';
import styled from 'styled-components';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Download from '@material-ui/icons/GetApp';
import { useDeleteDocumentFileMutation } from '../../../redux/rtkQuery/apiSlice';
import { downloadSinglePermitDocumentWithFullName } from '../../../api/projectDocuments';
import { dataURLtoFile } from '../../../modules/clientModule/utils';

const FileEntry = ({ file, row,  fileType, refetch }) => {
    const [deleteDocumentFile, {isLoading}] = useDeleteDocumentFileMutation();
    const onDelete = async (e:any, id: number) => {
        e.stopPropagation();
        const formData = new FormData();
        formData.append('document_id', row.pk);
        formData.append('file_id', `${id}`);
        formData.append('file_type', fileType);
        await deleteDocumentFile(formData);
        await refetch();
    }

    const onClick = async () => {
      let a = document.createElement('a');     
      a.download = file.fileName;
      if(file.file.indexOf('amazonaws')){
        let apiId = 'admin_file_id';
        let fileInfo = row.adminFiles.find(f => f.id === file.id);
        if(!fileInfo) {
          fileInfo = row.clientFiles.find(f => f.id === file.id);
          apiId = 'client_file_id';          
        }

        if(fileInfo){
          const data = {
            apiId,
            fileId: file.id,
            documentPk: row.pk
          }
          const result = await downloadSinglePermitDocumentWithFullName(data);
          var myFile = dataURLtoFile(`data:application/pdf;application/docx;base64,${result.data.file}`, result.data.fileName);
          let url = window.URL.createObjectURL(myFile);
          let a = document.createElement('a');
          a.href = url;
          a.download = result.data.fileName;
          a.click();          
        }
      } else {
        a.href = file.file;
      }
      
      a.click();
    }

  return (
    <Wrapper onClick={onClick}>
      {file.fileName}
      <Icons>
        <Icon onClick={(e) => onDelete(e,file.id)}>
          <DeleteForeverIcon />
        </Icon>
      </Icons>
    </Wrapper>
  );
};

export default FileEntry;

const Wrapper = styled.div`
  margin-bottom: 17px;
  display: flex;
  justify-content: space-between;
  text-decoration: underline;
  color: blue;
  cursor:pointer;
`;

const Icons = styled.div`
  display: flex;
  flex-direction: row;
`;

const Icon = styled.div`
  color: var(--secondaryText);
  &:hover {
    color: #76cfec;
  }
`;
