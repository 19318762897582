import * as React from 'react';
import { saveAs } from 'file-saver';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import CommentIcon from '@material-ui/icons/RateReview';
import {ToggleOffRounded} from '@material-ui/icons';
import {
  getCustomActivities,
} from '../../api/activities';
import {
  getPermitDocuments,
  createPermitDocument,
  updatePermitDocument,
  downloadAllPermitDocuments,
  deletePermitDocument, updatePermitDocumentComment, downloadSinglePermitDocuments, updateIsClientAvailable,
} from '../../api/projectDocuments';
import useTablePage from '../../hooks/useTablePage';

import Table from '../../organisms/Table';
import tableColumnsFormatter from '../../utils/tableColumnsFormatter';
import {
  ModalDefaultDraggable,
  ModalDeleteConfirmation,
} from '../../molecules';
import PermitDocumentForm from '../../forms/PermitDocumentForm/PermitDocumentForm';
import ModalInfo from '../../molecules/ModalInfo';
import { convertTableBoolean } from '../../utils/convertTableValues';
import { getVocabulariesDataList } from '../../api/vocabulary';
import { GET_ALL_QUERY_PARAMS } from '../../constants';
import PermitDocsSend from '../../organisms/PermitDocsSend';
import DocumentCommentsPage from "../DocumentCommentsPage";
import styled from "styled-components";
import openInNewTab from '../../utils/openInNewTab';
import DocumentsTable from './DocumentsTable/DocumentsTable';
import { useGetProjectDocumentsQuery, useGetVocabularyQuery } from '../../redux/rtkQuery/apiSlice';


const DocumentsPage = ({
  permit,
  work,
  building,
  isAddDocument,
  setAddDocument,
  isDownloadAllDocs,
  setDocsAvailable,
  setDownloadAllDocs,
  setDocsWaitingOnClient,
  setDisableSend,
  isSendDocs,
  setSendDocs,
  isInspector,
  isViewMode,
  initialValues,
  cameFromSearch,
  handleDocumentData,
  isLocked,
}: Props) => {
  const {
    isModalOpen,
    handleCloseModal,
    createOrUpdate,
    modalError,
    modalSuccess,
    modalTitle,
    errors,
    handleAdd,
    handleCloseModalInfo,
    modalErrorTitle,
    tableManuallyUpdate,
    updateTable,
    values,
    setValues,
    handleEdit,
    handleDelete,
    selectedItem,
    setSelectedItem,
    deleteById,
  } = useTablePage({
    createEndpoint: createPermitDocument,
    updateEndpoint: updatePermitDocument,
    deleteEndpoint: deletePermitDocument,
    modalCreateTitle: 'Add Document',
    modalUpdateTitle: isViewMode? ' ' :'Update Document',
  });

  const [statuses, setStatuses] = React.useState(null);
  const { data: voca } = useGetVocabularyQuery(10);
  const [documents, setDocuments] = React.useState([]);
  const [refetchParams, setRefetchParams] = React.useState<any>();
  const { data, refetch } = useGetProjectDocumentsQuery(refetchParams, { skip: refetchParams === undefined });
  const [documentComments, showDocumentComments] = React.useState(false);
  const [isProposalApproved, setIsProposalApproved] = React.useState(false);
 const downloadDocumentsZip = response => {
    const byteCharacters = atob(response.data.file);
    const byteNumbers = new Array(byteCharacters.length);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {
      type: 'application/zip',
    });
    saveAs(blob, 'documents.zip');
  };

  const downloadDocuments = response => {
    const byteCharacters = atob(response.data.file);
    const type = response.data.contentType.split(";")[0]
    const fileName = response.data.fileName
    const byteNumbers = new Array(byteCharacters.length);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {
      type: type,
    });
    saveAs(blob, fileName);
  };

React.useEffect(() => {
  if(voca && voca.results){
    setStatuses(voca.results);
  }
}, [voca]);

  React.useEffect(() => {
    if (isAddDocument) {
      handleAdd();
    }
  }, [isAddDocument]);

  React.useEffect(() => {
    if (isDownloadAllDocs && documents?.length) {
      const availableForDownload = documents?.filter(el => el?.isCompleted)
      const docsPks = availableForDownload.map(el => el?.pk);
      Promise.all([downloadAllPermitDocuments(docsPks)])
        .then(([response]) => {          
          downloadDocumentsZip(response);
        })
        .catch(() => {})
        .finally(() => setDownloadAllDocs(false));
    }
  }, [isDownloadAllDocs]);

  const modifiedGetPermitDocuments = params => {
    return Promise.all([
      // getPermitDocuments(params, permit?.toString()),
      getCustomActivities({
        permit_id: permit,
        ordering: null,
        event_type: "Activity"
      }),
      getCustomActivities({
        ...params,
        permit_id:permit,
        sort_by: params?.ordering,
        ordering:null,
        event_type:"Activity",
        activity_type_name:"Document",
      }),
      getVocabulariesDataList({
        ...GET_ALL_QUERY_PARAMS,
        vocabularyEntity: 10,
      })
    ]).then(([activitiesResponse,documentsResponse,statusesResponse]) => {
      const completedProposal = activitiesResponse?.data?.results?.find(activity => activity?.activityTypeName?.toLowerCase() === "proposal" 
      && activity?.isCompleted && activity?.statusName === "Approved")
      
      if(completedProposal) {
        setIsProposalApproved(true)
      }
      
      setStatuses(statusesResponse.data.results);
      const completedDocs = documentsResponse.data.results?.filter(el => el?.isCompleted)
      if(completedDocs?.length) {
        setDocsAvailable(true);
      }      
      if (documentsResponse.data.results?.length) {
        setDocuments(documentsResponse.data.results);
        handleDocumentData(documentsResponse?.data?.results?.filter(doc => (completedProposal || (doc.settingDoc === null))))
        if(completedProposal){
          const clientDocs = documentsResponse.data.results?.filter(doc => doc?.isClientAvailable)          
          if(!clientDocs?.length) {
            setDisableSend(true)
          } else {
            setDisableSend(false)
          }

        } else {
          const clientDocs = documentsResponse.data.results?.filter(doc => (doc?.isClientAvailable && !doc?.settingDoc))          
          if(!clientDocs?.length) {
            setDisableSend(true)
          } else {
            setDisableSend(false)
          }
        }
      }
      return {
        ...documentsResponse,
        data: {
          ...documentsResponse.data,
          results: documentsResponse.data.results?.map(
            document => {
              const currentStatus = statusesResponse.data.results?.find(
                el => el?.id === document.status,
              );
              if (
                currentStatus?.title === 'Waiting on client'
              ) {
                setDocsWaitingOnClient(true);
              }
              return {
                ...document,
                clientFiles:
                    document.clientFiles &&
                    document.clientFiles?.map(
                      file => file?.fileName,
                    ),
                    documentFile:
                    document.clientFiles &&
                    document.clientFiles?.map(
                      file => file?.file,
                    ),
                  // attachmentsId: document.clientFiles &&
                  // document.clientFiles?.map(
                  //   file => file?.id,
                  // ),
                  // attachmentsFile: document.clientFiles &&
                  // document.clientFiles?.map(
                  //   file => file?.file,
                  // ),
                status: currentStatus?.title,
                lastComment: document.lastComment?.replace(
                  /(<([^>]+)>)/gi,
                  ' ',
                ),
                submitted:
                  currentStatus?.title === 'Submitted',
              };
            }
          )
          .filter(
            document => (completedProposal || (document.settingDoc === null))
          ),
        },
      };
    });
  };

  const callRefetch = async () => {
    await refetch();
  }
  const inspectorGetPermitDocuments = params => {
    return Promise.all([
      getPermitDocuments(params, permit?.toString()),
      getVocabulariesDataList({
        ...GET_ALL_QUERY_PARAMS,
        vocabularyEntity: 10,
      }),
    ]).then(([documentsResponse, statusesResponse]) => {
      if (documentsResponse.data.results?.length) {
        setDocsAvailable(true);
        setDocuments(documentsResponse.data.results);
      }
      setStatuses(statusesResponse.data.results);
      return {
        ...documentsResponse,
        data: {
          ...documentsResponse.data,
          results: documentsResponse.data.results?.map(
            document => {
              const currentStatus = statusesResponse.data.results?.find(
                el => el?.id === document.status,
              );
              if (
                currentStatus?.title === 'Waiting on client'
              ) {
                setDocsWaitingOnClient(true);
              }
              return {
                ...document,
                status: currentStatus?.title,
                submitted:
                  currentStatus?.title === 'Submitted',
              };
            },
          ),
        },
      };
    });
  };

  const columns = column => {
    if (
      [
        'pk',
        'permit',
        'jurisdiction',
        'work',
        'building',
        'adminFile',
        'clientFile',
        'submitted',
        'type',
        'settingDoc',
        'isForClient',
        'isClientAvailable',
        'documentFile',
        'clientFileName',
        'isLocked',
      ].includes(column)
    ) {
      return null;
    }

    switch (column) {
      case 'isCompleted':
        return 'Completed';
      case 'name':
        return 'Document Name';
      case 'adminFileName':
        return 'Admin File';
      case 'clientFiles':
        return 'Client File';
      default:
        return tableColumnsFormatter(column);
    }
  };

  const columnsForInspector = column => {
    if (
      [
        'pk',
        'permit',
        'jurisdiction',
        'work',
        'building',
        'adminFile',
        'clientFile',
        'submitted',
        'lastComment',
      ].includes(column)
    ) {
      return null;
    }

    switch (column) {
      case 'isCompleted':
        return 'Completed';
      case 'name':
        return 'Document Name';
      case 'adminFileName':
        return 'Admin File';
      case 'clientFiles':
        return 'Client File';
      default:
        return tableColumnsFormatter(column);
    }
  };

  const columnsOrder = [
    'isCompleted',
    'name',
    'adminFileName',
    'clientFiles',
    'status',
    'lastComment',
  ];

  const columnsOrderForInspector = [
    'isCompleted',
    'name',
    'adminFileName',
    'clientFileName',
    'status',
  ];

  const handleCloseDocModal = async () => {
    await refetch()
    if (!modalError && !modalSuccess) {
      setAddDocument(false);
      showDocumentComments(false);
      setValues(false);
      handleCloseModal();
    }
  };

  const handleCloseInfo = () => {
    updateTable();
    handleCloseModalInfo();
    setAddDocument(false);
  };

  const downloadSingleSelectedDoc = (row, clickedColumn) => {
    
    if (row) {
      const docPk = [row.pk];
      let isAdminFile = clickedColumn === "adminFileName"
      let isClientFile = clickedColumn === "clientFiles"
      Promise.all([downloadSinglePermitDocuments({docPk, admin: isAdminFile, client: isClientFile})])
        .then(([response]) => {
          downloadDocuments(response);
        })
        .catch(() => {})
        .finally(() => setDownloadAllDocs(false));
    }
  };

  const toggleForCient = (row) => {
    if(row) {
      updateIsClientAvailable({id: row?.pk, isClientAvailable: !row?.isClientAvailable})
        .then((response) => {
          updateTable()
        })
        .catch(() => {})
    }
  }

  const downloadSelectedDoc = row => {
    if (row) {
      const docPk = [row.pk];
      Promise.all([downloadAllPermitDocuments(docPk)])
        .then(([response]) => {
          downloadDocuments(response);
        })
        .catch(() => {})
        .finally(() => setDownloadAllDocs(false));
    }
  };

   const openDocumentComments = row => {
    showDocumentComments(true);
    setValues(row);
  };

  const additionalActions = () => {
    if (isInspector) {
      return [
      {
        title: 'Notes',
        handler: openDocumentComments,
        Icon: CommentIcon,
      },
    ];
    }
    else {
      return [
        {
          title: 'Download files',
          handler: downloadSelectedDoc,
          Icon: SaveAltIcon,
        },
        {
          title: 'Toggle',
          handler: toggleForCient,
          Icon: ToggleOffRounded,
        },
      ];
    }
  };

  const handleCellClick = (rowValue, clickedColumn) => {
    openInNewTab(rowValue.adminFile);
    
   /* if (clickedColumn === 'clientFileName') {  Commented by Sandeep revisit during client module
      saveAs(rowValue.clientFile, 'documents.zip');
    } else {
      saveAs(rowValue.adminFile, 'documents.zip');*/
      // downloadSingleSelectedDoc(rowValue, clickedColumn);
    /*}*/
  };

  const handleCellClickClientFile = (rowValue, column) => {
    if (
      rowValue.documentFile &&
      Array.isArray(rowValue.documentFile)
    ) {
      const link = rowValue.documentFile[0];
      openInNewTab(link);
    } else {
      openInNewTab(rowValue.documentFile);
    }
  };

  const onAddDocument = async (data:any) => {
    if(data.id){
      //update
      await updatePermitDocument(data);
    } else {
      //create
      await createPermitDocument(data);
    }
     
     if(refetch){
      await refetch();
     }
    handleCloseDocModal();
     
  }

  const cellCallbackFnIsOn = [
    'adminFileName',
    'clientFiles',
  ];

  return (
    <>
     <Container>
      {isInspector ?
          <Table
              customSearch
              useViewModeInsteadEdit={isViewMode}
              rows={inspectorGetPermitDocuments}
              columns={columnsForInspector}
              columnsOrder={columnsOrderForInspector}
              additionalRequestParams={{
                work,
                building,
              }}
              manuallyUpdate={tableManuallyUpdate}
              formatValue={convertTableBoolean}
              onEdit={handleEdit}
              onConfigure={null}
              onDelete={null}
              additionalActions={additionalActions}
              handleCellClick={handleCellClick}
              cellCallbackFnIsOn={cellCallbackFnIsOn}
              docs
          />
          : 
          <DocumentsTable permitId={permit} handleEdit={handleEdit} setRefetchParams={setRefetchParams}/>
        }
      <PermitDocsSend
        permit={permit}
        isSendDocs={isSendDocs}
        setSendDocs={setSendDocs}
        documents={documents}
        statuses={statuses}
      />
      {isInspector && isModalOpen && (
        <StyledModalDraggable
          title={modalTitle}
          onClose={handleCloseDocModal}
        >
          <PermitDocumentForm
            onSubmit={createOrUpdate}
            initialErrors={errors}
            modalError={modalError}
            addButtonText="Add Document"
            updateButtonText="Update Document"
            permit={permit}
            statuses={statuses}
            initialValues={values}
            isReadOnly={true}
            isSimulateDeleted={isViewMode}
            handleCloseDocModal={handleCloseDocModal}
            callRefetch={callRefetch}
          />
        </StyledModalDraggable>
      )}
      {!isInspector && isModalOpen && (
        <ModalDefaultDraggable
          title={modalTitle}
          onClose={handleCloseDocModal}
        >
          <PermitDocumentForm
            onSubmit={onAddDocument}
            initialErrors={errors}
            modalError={modalError}
            addButtonText="Add Document"
            updateButtonText="Update Document"
            permit={permit}
            statuses={statuses}
            initialValues={values}
            isReadOnly={false}
            cameFromSearch={cameFromSearch}
            handleCloseDocModal={handleCloseDocModal}
            callRefetch={callRefetch}
          />
        </ModalDefaultDraggable>
      )}
      {selectedItem && (
        <ModalDeleteConfirmation
          onCancel={() => setSelectedItem(null)}
          onConfirm={() => deleteById(selectedItem.pk)}
        >
          <strong>{selectedItem.name}</strong>
        </ModalDeleteConfirmation>
      )}
      {(modalSuccess || modalError) && (
        <ModalInfo
          timeToClose={5000}
          isError={modalError}
          onClose={handleCloseInfo}
          errorMsg={modalErrorTitle}
        />
      )}
      </Container>
      {documentComments &&(
        <StyledModalDraggable
          title={'Document Notes'}
          onClose={handleCloseDocModal}
          extraWide
        >
      <DocumentCommentsPage
          initialValues={values}
          postRequest={updatePermitDocumentComment}
          isViewMode={isViewMode}
        />
        </StyledModalDraggable>
      )}

    </>
  );
};

const Container = styled.fieldset`
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  border: none;
  text-align: center;
`;

const StyledModalDraggable = styled(ModalDefaultDraggable)`
  height: 100%;
  overflow: inherit;
`;

interface Props {
  permit: number;
  work?: number;
  building?: number;
  isAddDocument: boolean;
  isSendDocs: boolean;
  setAddDocument: (data: boolean) => void;
  isDownloadAllDocs: boolean;
  setDocsAvailable: (data: boolean) => void;
  setDownloadAllDocs: (data: boolean) => void;
  setDocsWaitingOnClient: (data: boolean) => void;
  setSendDocs: (data: boolean) => void;
  isInspector: boolean;
  isViewMode?: boolean;
  initialValues?: any;
  cameFromSearch?:boolean;
  handleDocumentData:(data:any)=>void;
  isLocked?: boolean;
}

export default DocumentsPage;
