import React, { FC } from 'react';
import styled from 'styled-components';
import Delete from '@material-ui/icons/DeleteForever';

type Props = {
  documentFiles: any[];
  setValue: Function;
  forControl: string;
};
const FilesBubbles: FC<Props> = ({ forControl, documentFiles, setValue }) => {
    const onDelete = file => {
        let counter = 0;
        const _files = Object.keys(documentFiles).reduce((filesObj,key)=>{
            const _file = documentFiles[key];
            if(_file !== file){
                filesObj[counter++] = _file;
            }
            return filesObj;
        },{});
        
        setValue(forControl,_files);
    }
  return (
    <Wrapper>
      {documentFiles && (
        <>
          {Object.keys(documentFiles).map((doc, index) => {
            return (
              <Bubble key={index}>
                {documentFiles[doc].name}
                <IconWrapper onClick={()=>onDelete(documentFiles[doc])}>
                  <Delete />
                </IconWrapper>
              </Bubble>
            );
          })}
        </>
      )}
    </Wrapper>
  );
};
export default FilesBubbles;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 6px;
  top: 4px;
  cursor: pointer;
`;

const Bubble = styled.div`
  position: relative;
  width: fit-content;
  border: 1px solid #ccc;
  border-radius: 17px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 35px;
`;
