import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import StaticField from '../../../Common/Controls/StaticField';
import { RootState } from '../../../../../../redux/root';
import { useSelector } from 'react-redux';
import { Logo_Sky } from '../../../../../../constants/menu';
import { getPermitDocumentComments, getProjectDocumentFiles } from '../../../../../../api/projectDocuments';
import { CommentItem } from '../../../../types';
import CommentsTable from '../../../Common/CommentsTable';
import { Button } from '@material-ui/core';
import UploadFiles from '../../../Common/UploadFiles';
import { useForm } from 'react-hook-form';
import { saveClientDocuments } from '../../../../../../api/document';
import { dispatch } from '../../../../../../redux/store';
import { setFilesToUpload, setPermitDocuments, setSelectedDocument } from '../../../../../../redux/slices/documentsSlice';
import NotApplicableFiles from '../NotApplicableFiles';
import ActionFiles from './ActionFiles';

type Props = {};

const AvailableActions: FC<Props> = ({}) => {
  const selectedDocument = useSelector((s: RootState) => s.documents.SelectedDocument);
  const notApplicables = useSelector((s: RootState) => s.documents.notApplicables);
  const permitDocuments = useSelector((s: RootState) => s.documents.PermitDocuments);
  const filesToUpload = useSelector((s: RootState) => s.documents.filesToUpload);
  const { name, statusTitle, adminFile, pk } = selectedDocument;
  const [comments, setComments] = useState<CommentItem[]>([]);
  const { handleSubmit, register, errors, setValue, getValues, watch, reset } = useForm({});
  const [forceDisable, setForceDisable] = useState<boolean>(false)
  const filesSelected: boolean = watch('upload', false);
  const [filesToUploadMissing, setShowError] = useState(false);
  // const [uploadDisabled, setUploadDisabled] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  useEffect(() => {
    loadFiles();
  }, [pk]);

  useEffect(() => {
    if(forceDisable){
      onSubmit(null);
    }
  }, [forceDisable])
  const loadFiles = () => {
    if (pk) {
      const loadComment = async () => {
        const params = {
          offset: 0,
          is_active: true,
          is_deleted: false,
          limit: 100000000,
          system_message: false,
          doc: pk,
        };
        const result = await getPermitDocumentComments(`${pk}`, params);
        setComments(result.data.results);
      };
      loadComment();
    } else {
      setComments([]);
    }
  };

  const onSubmit = async form => {
    const formData = new FormData();
    const files = getValues('documentFile');
    if (files.length === 0) {
      setShowError(true);
      setErrorMsg('You must select files to upload!');
      return;
    }
    setShowError(false);

    if (!name) {
      setShowError(true);
      setErrorMsg('You must select a document!');
      return;
    }
    formData.append('permit_document_id', `${pk}`);
    files.forEach((file: any, index) => {
      formData.append('attachments_files', file);
    });

    try {
      await saveClientDocuments(formData);
      reset();
      loadFiles();
      dispatch(setFilesToUpload([]));

      const result = await getProjectDocumentFiles({}, `${pk}`);
      const _selectedDoc = {
        ...selectedDocument,
        clientFiles: result.data.clientFiles,
      };
      dispatch(setSelectedDocument(_selectedDoc));

      const _permitDocuments = permitDocuments.map(doc => {
        if (doc.pk === result.data.pk) {
          return _selectedDoc;
        }
        return doc;
      });
      dispatch(setPermitDocuments(_permitDocuments));
      setForceDisable(false);
      //api/v1/project_documents/2351/
    } catch (e) {
      console.log(e);
      setForceDisable(false);
    }
  };
  const allowFileUpload = statusTitle === 'Waiting on client' && !notApplicables[pk];
  return (
    <AllEntries>
      <StaticField label="Document Name:" value={name} link={{ url: adminFile, name }} />
      {adminFile && <FileHint>[Click here to open the document]</FileHint>}
      <StaticField label="Status:" value={statusTitle} />
      <StaticField label="Comments:" value={''} />

      {comments.length > 0 && <CommentsTable comments={comments} />}
      {statusTitle === 'Waiting on client' && <NotApplicableFiles />}      
      <ActionFiles />
      <form onSubmit={handleSubmit(onSubmit)}>
        {filesToUploadMissing && <Error>{errorMsg}</Error>}
        {allowFileUpload && (
          <FormItems>
            <UploadFiles showList={true} setValue={setValue} register={register} />
            {/* <ButtonMain label="Save" onClick={() => {}} isSubmit={true} /> */}
            <Button color="primary" variant="contained" disabled={filesToUpload.length === 0 || forceDisable} onClick={() => setForceDisable(true)}>
              Upload{' '}
            </Button>
          </FormItems>
        )}
      </form>
    </AllEntries>
  );
};

export default AvailableActions;

const AllEntries = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  left: -60px;
`;

const FormItems = styled.div`
  position: relative;
  left: 88px;
  width: 84%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  .MuiButton-contained {
    margin-top: 13px;
  }
  @media only screen and (max-width: 540px) {
    left: 55px;
    width: 382px;
  }
  button {
    width: 208px;
    margin: auto;
  }
`;

const FileHint = styled.div`
  font-size: 12px;
  margin-left: 235px;
  font-style: italic;
  color: ${Logo_Sky};
`;

const Error = styled.div`
  color: red;
  margin-left: 88px;
`;
