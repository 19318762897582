import React, { Fragment, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Logo_Sky } from '../../../../../constants/menu';
import DetailsHeaderComponent from '../../Common/DetailsHeaderComponent';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/root';
import SwitchCheckbox from '../../Common/Controls/SwitchCheckbox';
import ButtonMain from '../../Common/Controls/ButtonMain';
import { format } from 'date-fns';
import { setBottomDrawerOpened } from '../../../../../redux/slices/generalSlice';
import BottomDrawer from '../../Common/Containers/BottomDrawer';
import PayPopup from './PayPopup';
import MobileRow from './MobileRow';
import TableHeaderCell from '../../Common/Table/Header/TableHeaderCell';
import { sortTableHeader } from '../../../utils';
import ViewInvoiceModal from '../../../../../Components/InvoiceTool/ViewInvoiceModal';
import ViewInvoiceEye from './ViewInvoiceEye';
import { InvoiceWrapper } from '../../../../../forms/FeesSendForm';
import InvoiceTool from '../../../../../Components/InvoiceTool/InvoiceTool';
import useShowInvoicePdf from '../../../../../Components/InvoiceTool/useShowInvoicePdf';
import { setSelectedInvoicesToPay } from '../../../../../redux/slices/feesSlice';

const InvoicesItems = () => {
  const { selectedProjectInvoice, selectedInvoicesToPay } = useSelector((s: RootState) => s.fees);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [invoices, setInvoices] = useState([]);
  const [sortedFees, setSortedFees] = useState([]);
  const [scrollOn, setScrollOn] = useState<boolean>(false);
  const { tableSortingDirection, tableSortingKey } = useSelector((s: RootState) => s.general);
  const scrollRef = useRef<HTMLDivElement>();
  const dispatch = useDispatch();

  const [selectedFeesIds, setSelectedFeesIds] = useState<number[]>([]);
  const { handleInvoiceCellClick, reimbursableAttachment } = useShowInvoicePdf(selectedFeesIds);

  useEffect(() => {
    if (selectedProjectInvoice && selectedProjectInvoice.permit) {
      setInvoices(selectedProjectInvoice.permit.invoices);
      setSortedFees(selectedProjectInvoice.permit.invoices);
    }
  }, [selectedProjectInvoice.permit]);

  useEffect(() => {
    if (scrollRef.current) {
      setScrollOn(scrollRef.current.scrollHeight > scrollRef.current.clientHeight);
    }
  }, [sortedFees]);

  useEffect(() => {
    if (invoices.length === 0) {
      return;
    }
    const _fees = sortTableHeader(tableSortingDirection, tableSortingKey, [...invoices]);
    setSortedFees(_fees);
  }, [tableSortingDirection, tableSortingKey]);

  const checkAllItem = (check: boolean) => {
    const allItems = selectedProjectInvoice.permit.invoices.reduce((itemsObj, fee: any) => {
      const _itemObj = {
        ...itemsObj,
        [fee.id]: fee.paidAt !== '' ? false : check,
      };
      return _itemObj;
    }, {});
    dispatch(setSelectedInvoicesToPay(allItems));
  };
  const setSelectedItem = (e: any, id: number) => {
    const checked = e.target.checked;
    const _checkedItems = {
      ...selectedInvoicesToPay,
      [id]: checked,
    };
    dispatch(setSelectedInvoicesToPay(_checkedItems));
  };

  const payInvoices = () => {
    dispatch(setBottomDrawerOpened(true));
  };

  const onCancel = () => {
    dispatch(setBottomDrawerOpened(false));
  };

  return (
    <Wrapper>
      <InvoiceWrapper>
        <InvoiceTool fees={selectedFeesIds} reimbursableAttachment={reimbursableAttachment} />
      </InvoiceWrapper>
      <ViewInvoiceModal />
      <Header>Invoice List</Header>
      <DetailsHeaderComponent names={selectedProjectInvoice.projectPermit} address={selectedProjectInvoice.projectAddress} />
      <ButtonWrapper>
        <ButtonMain label="Pay" onClick={payInvoices} disabled={true} />
      </ButtonWrapper>
      <SingleRow isOdd={true} scrollOn={scrollOn}>
        <HeaderCell>
          <SwitchCell>
            <SwitchCheckbox
              name="CheckAll"
              value=""
              title=""
              onChange={e => checkAllItem(e.target.checked)}
              isRadio={false}
              label=""
              disabled={false}
              checked={selectAll}
            />
          </SwitchCell>
        </HeaderCell>
        <HeaderCell>
          <SortingWrapper>
            <TableHeaderCell label="Invoice Number" itemName="" hideFilter={true} sortingKey="invoiceNumber" theKey="invoiceNumber" />
          </SortingWrapper>
        </HeaderCell>
        <HeaderCell>
          <SortingWrapper>
            <TableHeaderCell label="Amount" itemName="" hideFilter={true} sortingKey="total" theKey="total" />
          </SortingWrapper>
        </HeaderCell>
        <HeaderCell>
          <SortingWrapper>
            <TableHeaderCell label="Status" itemName="" hideFilter={true} sortingKey="status" theKey="status" />
          </SortingWrapper>
        </HeaderCell>
        <HeaderCell>
          <SortingWrapper>
            <TableHeaderCell label="Date Paid" itemName="" hideFilter={true} sortingKey="paidAt" theKey="paidAt" />
          </SortingWrapper>
        </HeaderCell>
        <HeaderCell>View</HeaderCell>
      </SingleRow>
      <ItemsContainer ref={scrollRef}>
        {sortedFees.map((fee, index) => (
          <Fragment key={index}>
            <MobileRow isOdd={index % 2 > 0} key={index} fee={fee} setSelectedItem={setSelectedItem} />
            <SingleRow isOdd={index % 2 > 0}>
              <Cell noPadding={true}>
                {fee.status !== 'Pending' ? (
                  <NoSwitch />
                ) : (
                  <SwitchCell>
                    <SwitchCheckbox
                      name=""
                      value={`${selectedInvoicesToPay[fee.id]}`}
                      title=""
                      onChange={e => setSelectedItem(e, fee.id)}
                      isRadio={false}
                      label=""
                      disabled={false}
                      checked={selectedInvoicesToPay[fee.id]}
                    />
                  </SwitchCell>
                )}
              </Cell>
              <Cell>{fee.invoiceNumber.split('----')[0]}</Cell>
              <Cell>
                <AmountCell>${fee.total}</AmountCell>
              </Cell>
              <Cell>{fee.status}</Cell>
              <Cell>{fee.paidAt ? format(new Date(fee.paidAt), 'MM/dd/yyyy') : ''}</Cell>
              <Cell>
                <ViewInvoiceEye invoice={fee} setSelectedFeesIds={setSelectedFeesIds} handleInvoiceCellClick={handleInvoiceCellClick} />
              </Cell>
            </SingleRow>
          </Fragment>
        ))}
      </ItemsContainer>
      <BottomDrawer submitLabel="Cancel" onSubmit={onCancel}>
        <PayPopup />
      </BottomDrawer>
    </Wrapper>
  );
};

export default InvoicesItems;

const NoSwitch = styled.div`
  height: 47px;
  border-left: 1px solid grey;
`;

export const Red = styled.div`
  color: red;
`;

export const IconWrapper = styled.div<{ hasPdf: boolean }>`
  color: ${({ hasPdf }) => (hasPdf ? 'inherit' : 'red')};
  position: relative;
  left: 27px;
  cursor: ${({ hasPdf }) => (hasPdf ? 'pointer' : 'inherit')}; ;
`;

const AmountCell = styled.div`
  width: 300px;
  text-align: right;
  margin-right: 50px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  margin-top: 20px;
  margin-bottom: 16px;
`;

const SingleRow = styled.div<{ isOdd: boolean; scrollOn?: boolean }>`
  display: grid;
  grid-template-columns: ${({ scrollOn }) => (scrollOn ? '90px 1fr 160px 1fr 1fr 137px' : '90px 1fr 160px 1fr 1fr 120px')};
  border-bottom: 1px solid grey;
  color: ${Logo_Sky};
  background-color: ${({ isOdd }) => (isOdd ? '#FFFFF' : '#FCF9FC')};
  @media only screen and (max-width: 840px) {
    grid-template-columns: repeat(2, 1fr);
    display: none;
  }
  @media only screen and (max-width: 400px) {
    grid-template-columns: repeat(2, 1fr);
  }
  :hover {
    background-color: #efefef;
  }
`;

const SortingWrapper = styled.div`
  height: 52px;
  padding-top: 12px;
`;

export const HeaderCell = styled.div`
  font-weight: bold;
  display: flex;
  align-items: center;
  font-size: 21px;
  border-right: 1px solid grey;
  border-top: 1px solid grey;
  justify-content: center;
`;

const Cell = styled.div<{ noPadding?: boolean }>`
  font-size: 18px;
  display: flex;
  align-items: center;
  border-right: 1px solid grey;
  padding-left: ${({ noPadding }) => (noPadding ? '0' : '20px')};
`;

export const Wrapper = styled.div`
  margin: 22px;
`;

export const Header = styled.div`
  font-size: 29px;
  margin-bottom: 21px;
  color: ${Logo_Sky};
`;

const SwitchCell = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  border-left: 1px solid grey;
`;

export const ItemsContainer = styled.div`
  height: 38svh;
  overflow: auto;
  @media only screen and (max-width: 840px) {
    margin-left: -19px;
  }
`;

const SingleTableRow = styled.div<{
  isOdd: boolean;
  gridTemplateCols: string;
}>`
  display: grid;
  position: relative;
  grid-template-columns: ${({ gridTemplateCols }) => gridTemplateCols};
  border-bottom: 1px solid grey;
  background-color: ${({ isOdd }) => (isOdd ? '#FFFFF' : '#FCF9FC')};

  @media only screen and (max-width: 840px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media only screen and (max-width: 400px) {
    grid-template-columns: repeat(2, 1fr);
  }
  :hover {
    background-color: #efefef;
  }
`;
