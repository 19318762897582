import React, { useEffect, useState } from 'react';
import { IconWrapper } from './InvoicesItems';
import VisibilityIcon from '@material-ui/icons/Visibility';

const ViewInvoiceEye = ({ invoice, setSelectedFeesIds, handleInvoiceCellClick }) => {
  useEffect(() => {
    if (invoice) {
      const _selectedFeesIds = invoice.fees.map(fee => fee.id);
      setSelectedFeesIds(_selectedFeesIds);
    }
  }, [invoice]);
  const showInvoice = () => {
    handleInvoiceCellClick(invoice)
  };
  return (
    <IconWrapper hasPdf={true}>
      <div onClick={showInvoice}>
        <VisibilityIcon />
      </div>
    </IconWrapper>
  );
};

export default ViewInvoiceEye;
