import React, { useState } from 'react';
import { CheckboxWithLabel } from '../molecules';
import styled from 'styled-components';
import { ButtonInverted, ButtonMain } from '../atoms';

const ReviewDocuments = ({ documents, setDocuments, documentStatusData, setIsReviewDocumentsModalOpen, initialValues }) => {
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const handleCheckboxChange = (e, id, name) => {
    const newDocs = documents?.map(doc => {
      if (doc?.id === id) {
        return { ...doc, id, name, checked: e.target.checked };
      } else {
        return doc;
      }
    });

    const checkCount = newDocs.filter(d => d.checked);
    setAllChecked(checkCount.length === documents.length);
    setDocuments([...newDocs]);
  };

  const getStatus = stt => {
    return documentStatusData?.find(el => el?.id === stt)?.title;
  };
  const onSelectAll = e => {
    const docs = documents?.map(doc => {
      return {
        ...doc,
        checked: e.target.checked,
      };
    });
    setDocuments(docs);
    setAllChecked(e.target.checked);
  };

  return (
    <div>
      {!documents?.length ? (
        <h3>No uploaded documents exist for selection</h3>
      ) : (
        <>
          <CheckboxWrapper>
            <CheckboxInput type="checkbox" checked={allChecked} onChange={onSelectAll} />
            <div>Select All</div>
            <div></div>
          </CheckboxWrapper>

          {documents?.map((doc, index) => (
            <CheckboxWrapper key={index}>
              <CheckboxInput type="checkbox" checked={doc.checked} onChange={e => handleCheckboxChange(e, doc?.id, doc?.name)} />
              <div>{doc.name}</div>
              <div>{getStatus(doc.status)}</div>
            </CheckboxWrapper>
          ))}
          <ButtonsWrapper>
            <StyledButtonMain disabled={initialValues?.isCompleted} onClick={() => setIsReviewDocumentsModalOpen(false)}>
              Add
            </StyledButtonMain>
            <StyledButtonInverted onClick={() => setIsReviewDocumentsModalOpen(false)}>Cancel</StyledButtonInverted>
          </ButtonsWrapper>
        </>
      )}
    </div>
  );
};

export default ReviewDocuments;

const CheckboxInput = styled.input`
  accent-color: #0b6b9b;
`;
const CheckboxWrapper = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr 150px;
  margin-left: 60px;
  margin-right: 60px;
  margin-top: 20px;
  text-align: left;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 50px;
  justify-content: center;

  & button {
    height: 36px;
  }
`;

const StyledButtonMain = styled(ButtonMain)`
  margin-right: 16px;
  width: 112px;
`;
const StyledButtonInverted = styled(ButtonInverted)`
  width: 112px;
`;
