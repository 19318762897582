import React from 'react';
import { Pagination, TableWrapper, commonFilterMeta } from '../../../Common/Table/types';
import Table from '../../../Common/Table/Table';
import PaginationMobile from '../../../../../../pages/Responsive/PaginationMobile';
import useGetNewProjectData from '../../../../hooks/useGetNewProjectData';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/root';
import { headers } from '../../Inspections/InspectionsTable';
import useOnCommonTableActivity from '../../../../hooks/useOnCommonTableActivity';
import { ProjectPermitInvoiceType } from '../../../../../../Components/InvoiceTool/types';
import { setSelectedProjectInvoice } from '../../../../../../redux/slices/feesSlice';


const InvoicesTable = () => {
    useGetNewProjectData();
  const dispatch = useDispatch();
  const invoicesProjectList = useSelector((s: RootState) => s.fees.clientProjecPermitList);
  const { visibleItems } = useOnCommonTableActivity(invoicesProjectList);

  const onPermitFeeSelected = (item: ProjectPermitInvoiceType) => {
    dispatch(setSelectedProjectInvoice(item));
  }
  return (
    <TableWrapper>
      <Table
        collection={visibleItems}
        headers={headers}
        filterMeta={commonFilterMeta}
        gridTemplateCols="1fr 1fr 138px 138px 90px"
        setSelectedItemInSlice={onPermitFeeSelected}
      />
      <Pagination>
        <PaginationMobile showEverywhere={true} />
      </Pagination>
    </TableWrapper>
  );
};

export default InvoicesTable;
