import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { MarkPaidParams } from '../../../types';
import { useMarkPaidMutation } from '../../../../../redux/rtkQuery/apiSlice';
import { Loader } from '../../../../../atoms';
import styled from 'styled-components';
import { INVOICES_TO_PAY_KEY, SELECTED_PERMIT_ID } from '../../../../../settings';

const InvoicePaidSuccessPopup = ({ isSuccess }) => {
  const [open, setOpen] = React.useState(isSuccess);
  const [loader, setLoader] = useState<boolean>(true);
  const [markPaid] = useMarkPaidMutation();

  useEffect(() => {
    const selectedInvoicesDataToPay = JSON.parse(localStorage.getItem(INVOICES_TO_PAY_KEY));
    const permitId = JSON.parse(localStorage.getItem(SELECTED_PERMIT_ID));
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    localStorage.removeItem(INVOICES_TO_PAY_KEY);
    localStorage.removeItem(SELECTED_PERMIT_ID);
    const doMarkPaid = async () => {
      selectedInvoicesDataToPay.forEach( async(inv) => {
        const Params: MarkPaidParams = {
          invoice_number: inv.invoiceNumber,
          fee_ids: inv.fees.map(f => f.id).join(','),
          permit_id: permitId,
          send_to_email: userInfo.email,
        };
        await markPaid(Params);
      });

      setLoader(false);
    };
    doMarkPaid();
  }, []);
  const handleClose = () => {
    setOpen(false);
    const url = new URL(window.location.href);
    url.searchParams.delete('payment_intent');
    url.searchParams.delete('payment_intent_client_secret');
    url.searchParams.delete('redirect_status');
    window.history.pushState({}, '', url);
  
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Payment Processed Successfully'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {!loader && <span>Thank you, your payment has been processed.</span>}
          { loader && <LoaderWrapper>
            <Loader />
            <div>Updating payment status</div>
          </LoaderWrapper>}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default InvoicePaidSuccessPopup;

const LoaderWrapper = styled.div`
  width: 500px;
  display: flex;
  font-size: 20px;
  gap: 26px;
  align-items: center;
`;
