import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import InvoicePage from './InvoicePage';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/root';
import { getNextInvoiceNumber } from '../../api/fees';
import { createInvoicePdf, setInvoiceData } from './InvoiceUtil';
import { setInvoicePDF } from '../../redux/slices/feesSlice';
import ViewInvoiceModal from './ViewInvoiceModal';

type Props = {
  selectedContact?: any;
  reimbursableAttachment?: string[];
  fees: number[];
};
const InvoiceTool: FC<Props> = ({ selectedContact, reimbursableAttachment, fees }) => {
  const itemRefs = useRef([]);
  const createSingleDoc = useRef<boolean>(false);
  const { invoiceBody: body, invoiceHeader } = useSelector((s: RootState) => s.fees);
  const [pages, setPages] = useState<InvoicePage[]>([]);
  const [grandTotal, setGrandTotal] = useState<number>(0);
  const dispatch = useDispatch();

  useEffect(() => {
    const loadData = async () => {
      if (selectedContact) {
        const fee_ids = fees.reduce((ids, id) => {
          if (!ids) {
            return `${id}`;
          }
          return `${ids},${id}`;
        }, '');
        const params = {
          fee_ids,
          contact_id: selectedContact.id,
        };
        const info = await getNextInvoiceNumber(params);
        const data: any = info.data;
        setInvoiceData(data, dispatch);
      }
    };
    loadData();
  }, [selectedContact]);
  useEffect(() => {
    if (body.length > 0 && body[0].lineItems.length > 0 && Object.keys(invoiceHeader).length > 0) {
      const MAX_ITEMS_PER_PAGE = 12;
      let counter = 0;
      let page;
      let _grandTotal = 0;
      const _pages = body[0].lineItems.reduce((pageArr, line) => {
        _grandTotal += Number(line.amount) * Number(line.quantity);
        if (counter === 0) {
          page = { lineItems: [] };
          pageArr.push(page);
        }
        page.lineItems.push(line);
        if (line.notes) {
          counter += 3;
        } else {
          counter++;
        }
        if (counter > MAX_ITEMS_PER_PAGE - 1) {
          counter = 0;
        }
        return pageArr;
      }, []);
      setPages(_pages);
      setGrandTotal(_grandTotal);
    }
  }, [body[0].lineItems.length,invoiceHeader]);
  useEffect(() => {
    const createPdf = async () => {
      if (itemRefs.current.length > 0) {
        const _base64 = await createInvoicePdf(itemRefs, reimbursableAttachment);
        const prefix = /data:application\/pdf;filename=generated.pdf;base64,/;
        dispatch(setInvoicePDF(_base64.replace(prefix,'')));
        setPages([]);
       }
    }
    if(itemRefs.current.length > 0 && pages.length > 0 ) {
      createSingleDoc.current = true;
      createPdf();
    }
  }, [pages]);
  return (
    <Wrapper>
      {pages.map((b, index) => {
        return (
          <InvoicePage
            grandTotal={grandTotal}
            ref={el => (itemRefs.current[index] = el)}
            key={index}
            lines={b.lineItems}
            isLastPage={index === pages.length - 1}
            totalPages={pages.length}
            index={index}
          />  
        );
      })}
    </Wrapper>
  );
};

export default InvoiceTool;

const Wrapper = styled.div`
  height: 11in;
`;
