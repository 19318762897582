import React, { useEffect, useState } from 'react';
import { useGetAllInspectorsQuery, useUpdateInspectionInspectorMutation } from '../../redux/rtkQuery/apiSlice';
import { InputBaseContainer, InputSelectWithLabel } from '../../molecules';
import styled from 'styled-components';
import { Loader } from '../../atoms';
import { debug } from 'console';

const InspectorDropdown = ({ row }) => {
  const { data = [],isFetching } = useGetAllInspectorsQuery(null);
  const [ updateInspectionInspector, {isLoading} ] = useUpdateInspectionInspectorMutation();
  const [inspectorValue, setInspectorValue] = useState<string>();


  useEffect(() => {
    const selected = data.find(d => row.inspectorName === d.label);
   if(selected){
        setInspectorValue(selected.value);        
   }
  },[data,row])
  const onChange = async e => {
    setInspectorValue(e.target.value);
    await updateInspectionInspector({
        id:row.id,
        inspector: Number(e.target.value),
        title: row.title,
        inspection_type: row.inspectionTypeId,
        status: row.status,
    });
  }
  return (
    <Wrapper>
      <select value={inspectorValue} onChange={onChange}>
        {data.map((item, index) => (
          <option key={index} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
      <LoaderWrapper>{isFetching || isLoading && <Loader />}</LoaderWrapper>
    </Wrapper>
  );
};

export default InspectorDropdown;

const Wrapper = styled.div`
  display: flex;
  select {
    width: 110px;
    margin-left: 0px;
  }
`;

const LoaderWrapper = styled.div`
  zoom: 0.35;
  position: relative;
  top: 5px;
  left: 5px;
`;
