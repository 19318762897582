import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../redux/root';

const FeesSendFormReimbursementIssue = ({ fees,setSendDisabled }) => {
  const [dataIsMissing, setDataIsMissing] = useState<boolean>(false);
  const permitFees = useSelector((s:RootState) => s.fees.permitFees);
  
  useEffect(() => {
    const _fees = permitFees.filter(pf => fees.indexOf(pf.id) > -1);   
    _fees.forEach( item => {
        if(item.name === 'Reimbursement' ){
            if(!item.file ||Number(item.amount) === 0){
                setDataIsMissing(true);
                setSendDisabled(true);
            }
          }
    })
  }, [fees]);
  return (
    <>
      {dataIsMissing ? (
        <ReimbursableErrorMsg>
          Every <Reim>Reimbursement</Reim> has to have an attachment and amount greater than $0.00
        </ReimbursableErrorMsg>
      ) : (
        null
      )}
    </>
  );
};

export default FeesSendFormReimbursementIssue;

const ReimbursableErrorMsg = styled.div`
  color: red;
`;

const Reim = styled.span`
  font-size: 20px;
  font-style: italic;
`;
