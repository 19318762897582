import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/root';
import { ButtonWithLoader, ModalDefaultDraggable } from '../../../../../molecules';
import { setPermitDocuments, setReviewComments, setSelectedDocument } from '../../../../../redux/slices/documentsSlice';
import { sendClientDocument, sendReviewComments } from '../../../../../api/document';
import { DocumentFileItem } from '../../../types';
import { Button } from '@material-ui/core';
import { loadDocumentsData } from './DocumentsTable';
import { DocStatus } from '../../../../../constants';
import { Loader } from '../../../../../atoms';

type Props = {
  setShowError: Function;
  setErrorIds: Function;
};
const SendButtonBar: FC<Props> = ({ setShowError, setErrorIds }) => {
  const { ReviewComments, permitActivityId, notApplicables, PermitDocuments: permitDocuments, SelectedDocumentPermit } = useSelector(
    (s: RootState) => s.documents,
  );
  const [showConfirmation, setShowConfirmation] = useState(false);
  const vocabulary = useSelector((s: RootState) => s.general.vocabulary);
  const [filesCount, setFilesCount] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [allIdsToSend, setAllIdsToSend] = useState([]);
  const [showLoader,setShowLoader] = useState<boolean>(false);
  const [disableSend, setDisableSend] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const waitingOnClient = permitDocuments.filter(p => p.status === DocStatus.WAITING_ON_CLIENT);
    setDisableSend(waitingOnClient.length === 0);
    setErrorMessage('');
  }, [permitDocuments, notApplicables]);

  const onSendClick = async () => {
    //fisrt checking for issues on the review-comments - if pass move on
    setErrorMessage('');
    //1. all are pristine
    //2. all have value in text
    let errorsFound = false;
    const errorIds = [];
    ReviewComments.forEach(item => {
      if (!item.pristine) {
        errorIds.push(item.id);
        errorsFound = true;
      }
      const value = item.clientComment;
      if (item.pristine && (value === undefined || value === null || value === '')) {
        errorIds.push(item.id);
        errorsFound = true;
      }
    });
    // setShowError(false);
    if (errorsFound) {
      setErrorIds(errorIds);
      setErrorMessage('Please reply (or save) for all reviewer comments');
      return;
    }

    //second checking for issues on the files - if pass move on
    //There are no files with "Waiting on client" status that have attachments!
    let totalFileCount = 0;
    let idsWithoutFilesAndApplicable = 0;
    const idsToSend = permitDocuments.reduce((ids, item) => {
      if (item.statusTitle == 'Waiting on client') {
        totalFileCount += item.clientFiles.length;
        ids.push(item.pk);

        if (item.clientFiles.length === 0) {
          if (!notApplicables[item.pk]) {
            idsWithoutFilesAndApplicable++;
          }
        }
      }
      return ids;
    }, []);

    if (idsWithoutFilesAndApplicable > 0) {
      setErrorMessage('Some documents with "Waiting on client" status missing attachments. Add files or mark as Not Applicable');
      return;
    }
    setAllIdsToSend(idsToSend);
    let fileCountExpression = '';
    switch (totalFileCount) {
      case 0:
        fileCountExpression = 'No files';
        break;
      case 1:
        fileCountExpression = 'One file';
        break;
      case 2:
        fileCountExpression = 'Two files';
        break;
      default:
        fileCountExpression = `${totalFileCount} files`;
    }
    setFilesCount(fileCountExpression);
    setShowConfirmation(true);
  };

  const persistReviewComments = async () => {
    setShowLoader(true);
    if (ReviewComments.length > 0) {
      let result = await sendReviewComments(permitActivityId, ReviewComments);
      if (result.status === 200) {
        const _ReviewComments = ReviewComments.map(item => ({
          ...item,
          isClientCommentVisible: true,
        }));
        dispatch(setReviewComments(_ReviewComments));
      }
    }

    setShowConfirmation(false);
    const formData = new FormData();
    formData.append('permit_document_ids', allIdsToSend.join(','));
    formData.append('is_deleted', 'false');
    let result = await sendClientDocument(formData);
    if (result.status === 200) {
      await loadDocumentsData(SelectedDocumentPermit, dispatch, vocabulary);
      dispatch(setSelectedDocument({} as DocumentFileItem));
    }
    setShowLoader(false);
  };

  return (
    <>
      <ButtonWrapper onMouseLeave={() => setShowError(false)}>
        <Error>{errorMessage}</Error>
        {showLoader && <Loader />}
        <Button color="primary" variant="contained" onClick={onSendClick} disabled={disableSend}>
          Send
        </Button>
      </ButtonWrapper>
      {showConfirmation && (
        <ModalDefaultDraggable title="Confirmation" onClose={() => setShowConfirmation(false)}>
          <>
            <p>
              {filesCount} will be sent to your project manager. Once you send the documents, you will not be able to upload or send any
              additional documents, or add any further comments until the review is completed. Would you like to proceed?
            </p>
            <br />
            <Buttons>
              <ButtonWithLoader loading={false} onClick={persistReviewComments}>
                Yes
              </ButtonWithLoader>
              <ButtonWithLoader loading={false} onClick={() => setShowConfirmation(false)}>
                No
              </ButtonWithLoader>
            </Buttons>
          </>
        </ModalDefaultDraggable>
      )}
    </>
  );
};

export default SendButtonBar;

const ButtonWrapper = styled.div`
  width: 100%;
  padding: 12px;
  background-color: var(--secondary);
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid grey;
  button {
    width: 150px;
    margin-left: 10px;
  }
`;

const Error = styled.div`
  color: red;
  margin-right: 20px;
  margin-top: 5px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-evenly;
`;
