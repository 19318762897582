import React, { FC, useEffect, useState } from 'react';
import { Pagination, TableHeader, TableWrapper, commonFilterMeta } from '../../Common/Table/types';
import { updatePagination } from '../../../utils';
import useFilterActions from '../../../../../pages/Responsive/useFilterActions';
import PaginationMobile from '../../../../../pages/Responsive/PaginationMobile';
import Table from '../../Common/Table/Table';
import useGetNewProjectData from '../../../hooks/useGetNewProjectData';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/root';
import { setRowsCount } from '../../../../../redux/slices/paginationSlice';
import { getCustomActivities } from '../../../../../api/activities';
import { setPercentComplete, setPermitDocuments } from '../../../../../redux/slices/documentsSlice';
import useOnCommonTableActivity from '../../../hooks/useOnCommonTableActivity';
import { DocStatus } from '../../../../../constants';

export const headers: TableHeader[] = [
  {
    label: 'Project Name/Permit Name',
    sortingKey: 'namePermitName',
    itemName: 'namePermitName',
  },
  {
    label: 'Address',
    sortingKey: 'address',
    itemName: 'address',
  },
  {
    label: 'Quantity',
    theKey: 'quantity',
    itemName: 'quantity',
    hideFilter: true,
  },
  {
    label: 'Status',
    sortingKey: 'status',
    itemName: 'status',
  },
  { label: 'Detail', hideFilter: true, itemName: '' },
];

type Props = {
  onPermitItemSelected: Function;
}

const DocumentsTable:FC<Props> = ({onPermitItemSelected}) => {
  useGetNewProjectData();
  const permitDocumentsList = useSelector((s: RootState) => s.documents.AllPermitsWithDocs);  
  const { visibleItems } = useOnCommonTableActivity(permitDocumentsList);

  return (
    <TableWrapper>
      <Table
        collection={visibleItems}
        headers={headers}
        filterMeta={commonFilterMeta}
        gridTemplateCols="1fr 1fr 138px 138px 90px"
        setSelectedItemInSlice={onPermitItemSelected}
      />
      <Pagination>
        <PaginationMobile showEverywhere={true} />
      </Pagination>
    </TableWrapper>
  );
};

export default DocumentsTable;

export const loadDocumentsData = async (item,dispatch,vocabulary) => {
  const params = {
      is_deleted:false,
      limit:100000000,
      offset:0,
      permit_id: item.permitId,
      event_type:'Activity',
      activity_type_name:'Document'
  }
  const result = await getCustomActivities(params);
  const getStatusName = (code: number) => {
    const vocaItem = vocabulary.find(v => v.id === code);
    return vocaItem.title;
  }
  const items = result.data.results.map(item => {
    return {
      ...item,
      statusTitle: getStatusName(item.status)
    }
  });

  const {_items, percent, completedCount,rejectCount } = sortItemsByStatus(items);
  dispatch(setPermitDocuments(_items));
  dispatch(setPercentComplete({percent,completedCount,allCount:items.length,rejectCount}));
}

const sortItemsByStatus = (items: any) => {
  const completed = [];
  const underReview = [];
  const pending = [];
  const waitingOnClient = [];
  const rejected = [];

  items.forEach(item => {
    if(item.status === DocStatus.COMPLETED ) {
      completed.push(item);
    }
    if(item.status === DocStatus.UNDER_REVIEW ) {
      underReview.push(item);
    }
    if(item.status === DocStatus.WAITING_ON_CLIENT ) {
      waitingOnClient.push(item);
    }
    if(item.status === DocStatus.REJECTED ) {
      rejected.push(item);
    }
    if(item.status === DocStatus.PENDING) {
      pending.push(item);
    }
  })

  const percent = Math.floor(100*(completed.length/(items.length-rejected.length)))
  return {_items: [...waitingOnClient,...underReview,...pending,...completed,...rejected], percent, completedCount: completed.length,rejectCount: rejected.length};
}
