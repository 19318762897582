import React, { useEffect } from 'react';
import styled from 'styled-components';

const CharCounter = ({ notes, setValue, maxChars }) => {
    useEffect(()=>{
        if(notes && notes.length > maxChars){
            setValue('feeNote',notes.substr(0,notes.length - 1))
        }
    },[notes])
  return <Wrapper>{notes? notes.length : 0}/{maxChars}</Wrapper>;
};

export default CharCounter;

const Wrapper = styled.div`
  width: 100%;
  text-align: right;
  font-size: 14px;
`;
