import React, { useState } from 'react';
import styled from 'styled-components';
import { DocumentFileItem } from '../../../../types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AdjustIcon from '@material-ui/icons/Adjust';
import TripOriginIcon from '@material-ui/icons/RadioButtonUnchecked';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/root';
import { setOpenedTabIndex, setSelectedDocument } from '../../../../../../redux/slices/documentsSlice';
import { DocStatus } from '../../../../../../constants';

const FilesSection = () => {
  const permitDocuments = useSelector((s: RootState) => s.documents.PermitDocuments);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const dispatch = useDispatch();
  const onClick = (e, item, index) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedIndex(index);
    dispatch(setSelectedDocument(item));
    dispatch(setOpenedTabIndex(2));
  };
  return (
    <Files>
      {permitDocuments.map((item: DocumentFileItem, index: number) => {
        const count = item.clientFiles  && item.clientFiles.length ? `${item.clientFiles.length}` : '0';
        return (
          <FileItem key={index}>
            <FileItemCombo>
              <IconWrapper status={item.status}>
                {item.status === DocStatus.COMPLETED && <CheckCircleIcon />}
                {item.status === DocStatus.UNDER_REVIEW && <AdjustIcon />}
                {item.status === DocStatus.PENDING && <AdjustIcon />}
                {item.status === DocStatus.WAITING_ON_CLIENT && <TripOriginIcon />}
                {item.status === DocStatus.REJECTED && <NotInterestedIcon />}
              </IconWrapper>
              <FileName index={index} status={item.status} selectedIndex={selectedIndex} onClick={e => onClick(e, item, index)}>
                {item.name}
              </FileName>
            </FileItemCombo>

            <div>{`(${count})`}</div>
          </FileItem>
        );
      })}
    </Files>
  );
};

export default FilesSection;

const Files = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 42vh;
  overflow: auto;
`;

const FileItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 50px;
  width: 80%;
`;

const FileItemCombo = styled.div`
  display: flex;
  flex-direction: row;
`;

const getIconColor = (status) => {
  if (status === DocStatus.COMPLETED) return 'green';
  if (status === DocStatus.UNDER_REVIEW ) return 'blue';
  if (status === DocStatus.PENDING ) return 'blue';
  if (status === DocStatus.WAITING_ON_CLIENT ) return 'grey';
  return 'red';
}

export const IconWrapper = styled.div<{ status: number }>`
  color: ${({ status }) => getIconColor(status)};
`;

const FileName = styled.div<{ index: number; status: number; selectedIndex: number }>`
  color: ${({ status }) => getIconColor(status)};
  border: ${({ index, selectedIndex }) => {
    if (index === selectedIndex) {
      return '3px solid grey;';
    }
    return 'none';
  }};
  font-weight: ${({ index, selectedIndex }) => {
    if (index === selectedIndex) {
      return 'bold';
    }
    return 'inherit';
  }};
  border-left: none;
  border-right: none;
  margin-left: 10px;
  cursor: pointer;
  &:hover {
    background-color: #ccc;
  }
`;
