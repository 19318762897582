import React, { FC } from 'react';
import { FeeItemType } from '../../../types';
import styled from 'styled-components';
import SwitchCheckbox from '../../Common/Controls/SwitchCheckbox';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { IconWrapper, Red } from './InvoicesItems';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/root';

type Props = {
  isOdd: boolean;
  key: number;
  fee: FeeItemType;
  setSelectedItem: Function;
};
const MobileRow: FC<Props> = ({ isOdd, key, fee, setSelectedItem }) => {
  const selectedInvoicesToPay = useSelector((s:RootState) => s.fees.selectedInvoicesToPay);
  return (
    <Wrapper isOdd={isOdd}>
      <SwitchCell>
      {fee.status === 'Paid' ? (
                  <NoSwitch />
                ) : (
        <SwitchCheckbox
          name=""
          value={`${selectedInvoicesToPay[fee.id]}`}
          title=""
          onChange={e => setSelectedItem(e, fee.id)}
          isRadio={false}
          label=""
          disabled={false}
          checked={selectedInvoicesToPay[fee.id]}
        />)}
      </SwitchCell>
      <Values>
        <Cell>
          <Label>Fee Name</Label>
          {fee.name}
        </Cell>
        <Cell>
          <Label>Amount</Label>
          <AmountCell>${fee.amount}</AmountCell>
        </Cell>
        <Cell>
          <Label>Status</Label>
          {fee.status}
        </Cell>
        <Cell>
          <Label>Date Paid</Label>
          {/* {format(new Date(fee.datePaid || ''), 'MM/dd/yyyy')} */}
        </Cell>
        <CellIcon>
          <IconWrapper hasPdf={fee.invoicePdf !== ''}>
            <a target={fee.invoicePdf ? 'blank' : ''} href={fee.invoicePdf || '#'}>
              {fee.invoicePdf ? (
                <VisibilityIcon />
              ) : (
                <Red>
                  <VisibilityOff />
                </Red>
              )}
            </a>
          </IconWrapper>
        </CellIcon>
      </Values>
    </Wrapper>
  );
};

export default MobileRow;

const Wrapper = styled.div<{isOdd: boolean}>`
  display: none;
  @media only screen and (max-width: 840px) {
    display: grid;
    background-color: ${({ isOdd }) => (isOdd ? '#FFFFF' : '#FCF9FC')};
    grid-template-columns: 80px 1fr;
  }
`;

const NoSwitch = styled.div`
  height: 47px;
`;

const CellIcon = styled.div`
  grid-column: 2 / 3;
`;
const SwitchCell = styled.div`
  border: 1px solid gray;
`;

const Values = styled.div`
  display: grid;
  grid-template-columns: 170px 1fr;
  height: 130px;
  border: 1px solid grey;
  padding-left: 5px;
`;

const Cell = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  font-weight: bold;
  font-size: 10px;
`;

const AmountCell = styled.div`
  width: 75px;
  text-align: right;
  margin-right: 50px;
`;
