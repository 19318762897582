import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ReviewRecord from './ReviewRecord';
import { createReviewerComment } from '../../api/activities';
import { getReviewerComments } from '../../api/projectDocuments';

export type RecordType = {
    comment: string;
    reference: string;
    status: any;
    index: number;  
    id: number;  
}
const ReviewerCommentForm1 = ({reviewId}) => {
    const [records, setRecords] = useState<RecordType[]>([]);
    const [showSave, setShowSave] = useState<boolean>(false);
    const [statuses] = useState([{label: "Rejected", value: 0},{label: "Approved", value: 1},{label: "Not Applicable", value: 2}])
    useEffect(() => {
        const load = async () => {
            await getReviewerComments(reviewId);
            const response = await getReviewerComments(reviewId);
            const _records = response.data.map((d,ndx) => {
                const status = statuses.find(s => s.label === d.status);
                return {
                   comment: d.reviewerComment,
                   reference: d.reference,
                   status,
                   index: ndx + 1,
                   id: d.id
                }
            })
            setRecords(_records);
        }
        load();
    }, []);

    const getStatus = (value) => {
        if(isNaN(Number(value))){
            return value;
        }

        const _status = statuses.find(s => s.value === Number(value));
        return _status;
    }
    const saveAll = async () => {
        const getStatus = (stt) => {
            if(isNaN(stt)){
                return stt;
            }
            const status = statuses.find(s => s.value === Number(stt));
            return status;
        }
        const data = {
            reviewId: reviewId,
            reviewerCommentForm: records.map(r => {
                return {
                    id: r.id,
                    reference: r.reference,
                    reviewer_comment: r.comment,
                    status: getStatus(r.status)
                }
            })
        }
        const result = await createReviewerComment(data);
        setShowSave(true);
        setTimeout(()=>{
            setShowSave(false);
        },1000)
    }
    const onChange = (e, index) => {
        const name = e.target.name;
        const value = e.target.value;
       
        const _ndx = index - 1;
        const _records = records.map((r,ndx) => {
          if(ndx === _ndx){
            return {
                ...r,
                [name] : value
            }
          } 
          return r;
        })
        
        setRecords(_records);
    }


    const onClick = () => {
        const status = statuses.find(s => s.value === 0 );
        const _rec:RecordType = {
            comment: "",
            reference: "",
            status,
            index: records.length + 1,
            id: 0
        }
        const _records = [...records];
        _records.push(_rec);
        setRecords(_records);
    }
    return (
        <div>
            {records.map(r => <ReviewRecord showSave={showSave} record={r} onChange={onChange} statuses={statuses} saveAll={saveAll}/>)}
            <NewButton onClick={onClick}>
                + New
            </NewButton>
        </div>
    );
};

export default ReviewerCommentForm1;

const NewButton = styled.div`
 color: var(--primary);
 width: 100%;
 text-align: left;
 cursor: pointer;
`;
