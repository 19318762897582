import React from 'react';
import { CheckboxWithLabel, InputBaseContainer } from '../../molecules';
import { Controller } from 'react-hook-form';

const StatusCheckboxes = ({initialValues,control,errors,labels,cameFromSearch,isReadOnly}) => {
    return (
        <div>
            <InputBaseContainer>
              <Controller
                name="isPaid"
                control={control}
                valueName="paid"
                defaultValue={initialValues?.status === "Paid"}
                render={({ onChange, onBlur, value }) => (
                  <CheckboxWithLabel
                    error={errors.isPaid}
                    label={labels.isPaid}
                    checked={value}
                    onBlur={onBlur}
                    onChange={(e, flag) => onChange(flag)}
                    disabled={initialValues || isReadOnly // EA-361
                      // initialValues?.status === 'Paid' ||
                      // initialValues?.status === 'Pending' ||
                      // initialValues?.status === 'Paid with another method'
                    }
                  />
                )}
              />
            </InputBaseContainer>
            {/* Adding the paid with other method checkbox */}
            <InputBaseContainer>
              <Controller
                name="paidManually"
                control={control}
                valueName="paidManually"
                defaultValue={initialValues?.status === 'Paid by Another Method'}
                render={({ onChange, onBlur, value }) => (
                  <CheckboxWithLabel
                    error={errors.paidManually}
                    label={labels.paidManually}
                    checked={value}
                    onBlur={onBlur}
                    onChange={(e, flag) => onChange(flag)}
                    disabled={(
                      !initialValues?.invoiceId ||
                      initialValues?.status?.toLowerCase().includes("paid") || initialValues?.isVoid || isReadOnly) && !(initialValues?.status === 'Invoiced in QB')
                    }
                  />
                )}
              />
            </InputBaseContainer>
                      <InputBaseContainer>
              <Controller
                name="isVoid"
                control={control}
                valueName="checked"
                defaultValue={initialValues?.isVoid}
                render={({ onChange, onBlur, value }) => (
                  <CheckboxWithLabel
                    error={errors.void}
                    label={labels.void}
                    checked={value}
                    onBlur={onBlur}
                    onChange={(e, flag) => onChange(flag)}
                    disabled={!initialValues?.invoiceId || isReadOnly || initialValues?.isVoid // EA-361
                    || initialValues?.status === 'Paid' ||
                      // initialValues?.status === 'Pending' ||
                      cameFromSearch ||
                      initialValues?.status === 'Paid by Another Method'
                    }
                  />
                )}
              />
            </InputBaseContainer> 
        </div>
    );
};

export default StatusCheckboxes;