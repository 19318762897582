import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FieldValue, OneLine } from './LeftLogoArea';
import { InvoiceHeader } from './types';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/root';

const ADDRESS = 'invoice_address';
const PHONE = 'invoice_phone';
const RightSectionPart: FC = () => {
  const header = useSelector((s: RootState) => s.fees.invoiceHeader);
  const clearInvoiceNumber = (num: string) => {
    const SP = '----';
    if (num.indexOf(SP) > -1) {
      return num.split(SP)[0];
    }
    return num;
  };
  return (
    <Right>
      <TopSection>
        <Header1>ELITE PERMITS OF {header.franchiseName.toUpperCase()}</Header1>
        <Header2>{header.franchiseAddress}</Header2>
        <Header2>{header.franchisePhone}</Header2>

        <Header2>www.Elitepermits.com</Header2>
      </TopSection>
      <InvoiceHeaderSection>
        <div>INVOICE NUMBER:</div>
        <FieldValue>{clearInvoiceNumber(header.invoiceNumber)}</FieldValue>
        <div>LOCATION:</div>
        <FieldValue>{header.location}</FieldValue>
        <div>PROJECT MANAGER:</div>
        <FieldValue>{header.projectManager}</FieldValue>
        <div>PROJECT NAME:</div>
        <FieldValue>{header.projectName}</FieldValue>
        <div>PERMIT NAME:</div>
        <FieldValue>{header.permitName}</FieldValue>
        <div>PERMIT NUMBER:</div>
        <FieldValue>{header.permitNumber}</FieldValue>
        <div>COMPANY NAME:</div>
        <FieldValue>{header.companyName}</FieldValue>
        <div>DATE:</div>
        <FieldValue>{header.date}</FieldValue>
        <div>DUE DATE:</div>
        <FieldValue>{header.dueDate}</FieldValue>
      </InvoiceHeaderSection>
    </Right>
  );
};

export default RightSectionPart;

const InvoiceHeaderSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-left: 66px;
  font-size: 12px;
`;

const Right = styled.div`
  width: 50%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
`;

const TopSection = styled.div`
  font-weight: bold;
  text-align: center;
  background-color: #fff;
  margin: 45px;
  line-height: 28px;
`;

const Header1 = styled.div`
  font-size: 20px;
`;
const Header2 = styled.div`
  font-size: 11px;
`;
