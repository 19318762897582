export const settingsSubMenuLinks = [
  {
    id: 1,
    linkTo: 'jurisdiction',
    name: 'Jurisdiction',
  },
  {
    id: 2,
    linkTo: 'trades',
    name: 'Trades',
  },
  {
    id: 3,
    linkTo: 'documents',
    name: 'Documents',
  },
  {
    id: 4,
    linkTo: 'fees',
    name: 'Fees',
  },
  {
    id: 5,
    linkTo: 'scheduling',
    name: 'Scheduling',
  },
  {
    id: 6,
    linkTo: 'activities',
    name: 'Activities',
  },
  {
    id: 7,
    linkTo: 'inspection-type',
    name: 'Inspection Type',
  },
  {
    id: 8,
    linkTo: 'contact-type',
    name: 'Contact Type',
  },
  {
    id: 9,
    linkTo: 'report-setup',
    name: 'Report Setup',
  },
  {
    id: 10,
    linkTo: 'vocabulary',
    name: 'Vocabulary',
  },
  {
    id: 11,
    linkTo: 'messages',
    name: 'Messages',
  },
  {
    id: 12,
    linkTo: 'reference-codes',
    name: 'Reference Codes',
  },
];

export const settingsSetupLinks = [
  {
    id: 1,
    linkTo: 'report-setup',
    name: 'Reports',
  },
  {
    id: 2,
    linkTo: 'form-setup',
    name: 'Forms',
  },
];

export const reportsSubMenuLinks = [
  {
    id: 1,
    linkTo: 'sales',
    name: 'Sales',
  },
];

export const franchiseSubMenuLinks = [
  {
    id: 1,
    linkTo: 'jurisdiction',
    name: 'Jurisdiction',
  },
  {
    id: 2,
    linkTo: 'users',
    name: 'Users',
  },
  {
    id: 3,
    linkTo: 'stripe',
    name: 'Stripe',
  },
];

export interface SubMenuLink {
  linkTo: string;
  name: string;
  id: number;
}

export const Logo_Gold = '#D2B96D';
export const Logo_Sky = '#316B99';
export const Logo_Blue = '#0C1341';
export const Logo_Grey = '#7E8D8C';