import { Dialog, Modal, useMediaQuery, useTheme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/HighlightOff';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  setHasViewClicked,
  setInvoiceBody,
  setInvoiceHeader,
  setInvoicePDF,
  setIsGeneratingInvoice,
  setOpenInvoicePreview,
} from '../../redux/slices/feesSlice';
import { RootState } from '../../redux/root';
import { Loader } from '../../atoms';

const ViewInvoiceModal = () => {
  const dispatch = useDispatch();
  const { invoicePdf, openInvoicePreview } = useSelector((s: RootState) => s.fees);
  const [url, setUrl] = useState<string>('');

  useEffect(() => {
    if (!openInvoicePreview) {
      setUrl('');
    }
  }, [openInvoicePreview]);
  useEffect(() => {
    if (invoicePdf) {
      const bytes = atob(invoicePdf);
      let length = bytes.length;
      let out = new Uint8Array(length);

      while (length--) {
        out[length] = bytes.charCodeAt(length);
      }

      const blob = new Blob([out], { type: 'application/pdf' });
      const _url = URL.createObjectURL(blob);
      setUrl(_url);
    }
  }, [invoicePdf]);
  const handleClose = () => {
    dispatch(setHasViewClicked(false));
    dispatch(setIsGeneratingInvoice(false));
    dispatch(setInvoicePDF(''));
    dispatch(setInvoiceHeader({}));
    dispatch(setInvoiceBody([{ lineItems: [] }]));
    dispatch(setOpenInvoicePreview(false));
  };
  const src = 'data:application/pdf;base64,' + invoicePdf;
  return (
    <Wrapper>
      <Dialog fullScreen={true} onClose={handleClose} open={openInvoicePreview}>
        <Body>
          <CloseCircle onClick={handleClose}>
            <CloseIcon />
          </CloseCircle>
          <Header>Preview Document</Header>
          {url === '' && (
            <LoaderWrapper>
              <Loader></Loader>
            </LoaderWrapper>
          )}

          {url !== '' && <iframe src={`${url}#toolbar=1`} width="100%" height="95%"></iframe>}
        </Body>
      </Dialog>
    </Wrapper>
  );
};

export default ViewInvoiceModal;

const Body = styled.div`
  height: 100%;
  margin: 36px;
`;

const Wrapper = styled.div`
  .MuiDialog-paperWidthSm {
    max-width: 1500px;
  }
`;
const CloseCircle = styled.div`
  cursor: pointer;
  zoom: 1.5;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  padding-top: 10px;
`;

const Header = styled.div`
  width: 100%;
  font-size: 30px;
  text-align: center;
  margin-bottom: 25px;
`;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  zoom: 5;
`;
