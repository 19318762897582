import React, { useEffect, useState } from 'react';
import { InputBaseContainer, InputFileContainer } from '../../molecules';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import { getBase64 } from '../../utils/isDevMode';
import DeleteIcon from '@material-ui/icons/Delete';
import { viewInvoice } from '../../Components/InvoiceTool/InvoiceUtil';
import { useDispatch } from 'react-redux';
import { setInvoicePDF } from '../../redux/slices/feesSlice';

const FeeFileAttachment = ({ initialValues, control, errors, schema, isYupFieldRequired, setValue }) => {
  const [fileName, setFileName] = useState('');
  const dispatch = useDispatch();
  useEffect(() => {
    if (initialValues && initialValues.fileName) {
      setFileName(initialValues.fileName);
    }
  }, [initialValues]);
  const handleFileChange = e => {
    if (e.target.files[0]) {
      const name = e.target.files[0].name;
      getBase64(e.target.files[0], result => {
        setValue('file', result.replace(/data:application\/pdf;base64,/, ''));
        setValue('fileName', name);
      });
    }
    setFileName(e.target.files[0].name);
  };

  const onDelete = () => {
    setFileName('');
    setValue('file', null);
    setValue('fileName', null);
  };

  const viewFile = () => {
    dispatch(setInvoicePDF(initialValues.file));
    viewInvoice(initialValues.file, initialValues.fileName, true, dispatch, false);
  }
  return (
    <Wrapper>
      <InputBaseContainer>
        <Controller
          name="file"
          control={control}
          defaultValue={null}
          render={() => (
            <>
              <StyledButton htmlFor="file-upload">Choose File...</StyledButton>
              <StyledInputFile
                id="file-upload"
                error={errors.file}
                onChange={handleFileChange}
                accept="application/pdf"
                isRequired={!initialValues ? isYupFieldRequired('file', schema) : false}
              />
              <NameWrapper>
                <StyledFileName>
                  <div>
                    <span>File</span>:
                  </div>
                  
                </StyledFileName> 
                <FileNameLink onClick={viewFile}>{fileName}</FileNameLink>
                {fileName && (
                  <IconWrapper>
                    <DeleteIcon onClick={onDelete} />
                  </IconWrapper>
                )}
              </NameWrapper>
            </>
          )}
        />
      </InputBaseContainer>
    </Wrapper>
  );
};

export default FeeFileAttachment;

const Wrapper = styled.div`
  width: 35%;
`;

const StyledButton = styled.label`
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
`;

const StyledFileName = styled.h5`
  margin: 4px;
  & span {
    color: var(--primary);
  }
`;

const StyledInputFile = styled(InputFileContainer)`
  display: none;
`;

const NameWrapper = styled.div`
  display: flex;
`;

const IconWrapper = styled.div`
  cursor: pointer;
  padding-left: 1px;
  color: var(--primary);
`;

const FileNameLink = styled.div`
  text-decoration: underline;
  cursor: pointer;
  color: blue;
`;
