import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { createPermitDocument, updatePermitDocument } from '../../api/projectDocuments';
import styled from 'styled-components';

export type CrudType = 'Create' | 'Edit' | '';
type Props = {
  apiData: any;
  showConfirmDialog: boolean;
  setShowConfirmDialog: Function;
  crud: string;
  handleCloseDocModal: Function;
  callRefetch: any;
};

const ConfirmNoFilesDialog: FC<Props> = ({
  apiData,
  showConfirmDialog,
  setShowConfirmDialog,
  crud,
  handleCloseDocModal,
  callRefetch,
}) => {
  const onNo = async () => {
    if (crud === 'Create') {
      const result = await createPermitDocument(apiData);
      setShowConfirmDialog(false);
      handleCloseDocModal();
    }

    if (crud === 'Edit') {
      const result = await updatePermitDocument(apiData);
      setShowConfirmDialog(false);
      handleCloseDocModal();
    }
    callRefetch();
  };
  const onYes = () => {
    setShowConfirmDialog(false);
  };
  return (
    <Dialog  open={showConfirmDialog} onClose={() => {}}>
      <DialogTitle></DialogTitle>
      <DialogContent style={{width:'600px',height: '64px'}}>
        <DialogContentText>Do you want to attach a file?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onNo} color="primary" variant="contained" style={{marginLeft:'83px'}}>
          No
        </Button>
        <Button onClick={onYes} color="primary" variant="contained" autoFocus style={{marginRight:'83px'}}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmNoFilesDialog;

const DialogActions = styled.div`
   width: 100%;
   display: flex;
   justify-content: space-between;
   button{
    width: 172px;
    height: 28px;
    margin: 27px;
   }
`;

const DialogContentText = styled.div`
 width: 100%;
 text-align: center;
 font-size: 15px;
`;
