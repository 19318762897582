import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import StaticField from '../../Common/Controls/StaticField';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/root';
import { Logo_Sky } from '../../../../../constants/menu';
import { setNotApplicables, setSelectedDocument } from '../../../../../redux/slices/documentsSlice';

const NotApplicableFiles = () => {
  const { SelectedDocument, notApplicables } = useSelector((s: RootState) => s.documents);
  const [disabled, setDisabled] = useState<boolean>(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setDisabled(Object.keys(SelectedDocument).length === 0);
  }, [SelectedDocument]);
  const onChange = e => {
    // console.log(e.target.checked);
    const _napp = { ...notApplicables, [SelectedDocument.pk]: e.target.checked };
    dispatch(setNotApplicables(_napp));
  };
  return (
    <>
      <NotApplicable>
        <InputCheck>
          <input type="checkbox" onChange={onChange} disabled={disabled} checked={notApplicables[SelectedDocument.pk]} />
        </InputCheck>
        <FieldWrapper>
          <Label disabled={disabled}>Not Applicable</Label>
        </FieldWrapper>
        {/* <StaticField label="Not Applicable" value={''} /> */}
      </NotApplicable>
    </>
  );
};

export default NotApplicableFiles;

const InputCheck = styled.div`
  position: relative;
  left: 85px;
  top: 8px;
`;

const NotApplicable = styled.div`
  display: flex;
  gap: 10px;
`;

const FieldWrapper = styled.div`
  display: flex;
  margin: 6px;
  margin-left: 88px;
  font-size: 17px;
  color: ${Logo_Sky};
  a {
    color: ${Logo_Sky};
    &:visited,
    &:focus {
      color: ${Logo_Sky};
      text-decoration: underline;
    }
  }
`;

const Label = styled.div<{ disabled: boolean }>`
  font-weight: bold;
  margin-right: 8px;
  color: ${({ disabled }) => (disabled ? '#ccc' : '#316B99')};
`;
