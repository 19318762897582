import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';

const StripeElements = ({ clientSecret, stripePromise }) => {
  const options = {
    clientSecret: clientSecret,
  };
  return (
    <Elements stripe={stripePromise} options={options}>     
      <CheckoutForm />
    </Elements>
  );
};

export default StripeElements;
