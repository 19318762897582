import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import StripeElements from './StripeElements';
import { getStripeClientSecret } from '../../../../../api/fees';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/root';
import { PermitInvoiceType } from '../../../../../Components/InvoiceTool/types';
import StripeInvoicesTable from './StripeInvoicesTable';
import { getFranchiseStripeList } from '../../../../../api/franchise';
import _ from 'lodash';
import { loadStripe } from '@stripe/stripe-js';

const PayPopup = () => {
  const [clientSecret, setClientSecret] = useState<string>('');
  const [stripePromise,setStripePromise] = useState<any>();
  const email = useSelector((s:RootState) => s.userAuth.info.email);
  const { selectedProjectInvoice, totalStripeAmount } = useSelector((s: RootState) => s.fees);
  const [permit, setPermit] = useState<PermitInvoiceType>({} as PermitInvoiceType);

  useEffect(() => {
    const loadSecret = async () => {
      const invoiceNum = selectedProjectInvoice.permit.invoices.map(inv => {
        if(inv.invoiceNumber.indexOf('----') > -1) {
          return inv.invoiceNumber.split('----')[0];
        }
        return inv.invoiceNumber;
      }).join(',');
      const payload = {
        permit_id: permit.id,
        amount: totalStripeAmount,
        email,
        invoice_number: invoiceNum
      };
      let result = await getStripeClientSecret(payload);
      //@ts-ignore
      setClientSecret(result.data.clientSecret);

      //@ts-ignore
      result = await getFranchiseStripeList(permit.franchiseId);
      const pk = _.get(result, 'data.franchiseStripeSetting.stripePublishableKey');

      const _stripePromise = loadStripe(pk);
      setStripePromise(_stripePromise);
    };
    if (permit.id && totalStripeAmount > 0) {
      loadSecret();
    }
  }, [totalStripeAmount, permit]);

  useEffect(() => {
    if (selectedProjectInvoice) {
      setPermit(selectedProjectInvoice.permit);
    }
  }, [selectedProjectInvoice]);

  return (
    <Wrapper>
      <StripeInvoicesTable />
      <StripeFrame>
        {clientSecret && stripePromise && <StripeElements clientSecret={clientSecret} stripePromise={stripePromise} />}
      </StripeFrame>
    </Wrapper>
  );
};

export default PayPopup;

const StripeFrame = styled.div`
  width: 408px;
  height: 451px;
  margin: auto;
`;
const Wrapper = styled.div`
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 75vh;
  overflow: auto;
`;
