import React, { useState } from 'react';
import styled from 'styled-components';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import UnfoldMore from '@material-ui/icons/UnfoldMore';

const SortingHeader = ({ name, applySorting, field }) => {
  const [counter, setCounter] = useState<number>(0);

  const onClick = () => {
    const _counter = counter + 1;
    setCounter(_counter);
    switch (_counter % 3) {
      case 0:
        applySorting(undefined);
        break;
      case 1:
        applySorting(field);
        break;
      case 2:
        applySorting(`-${field}`);
        break;
    }
  };
  return (
    <Wrapper>
      <span>{name}</span>
      <Icon onClick={onClick}>
        {counter % 3 === 0 && <UnfoldMore />}
        {counter % 3 === 1 && <KeyboardArrowDown />}
        {counter % 3 === 2 && <KeyboardArrowUp />}
      </Icon>
    </Wrapper>
  );
};

export default SortingHeader;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Icon = styled.div`
  cursor: pointer;
  width: fit-content;
`;
