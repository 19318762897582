import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../redux/root';
import { viewInvoice } from '../Components/InvoiceTool/InvoiceUtil';
import { Loader } from '../atoms';
import { setHasViewClicked } from '../redux/slices/feesSlice';

const FeeSendFormViewInvoice = () => {
  const { invoicePdf, invoiceNumber } = useSelector((s: RootState) => s.fees);
  const [loader, setLoader] = useState<boolean>(false);
  const dispatch = useDispatch();

  const handleClick = useDebounce(() => {
    setLoader(false);
    viewInvoice(invoicePdf, invoiceNumber, true, dispatch);
  }, 3000);

  return (
    <ViewInvoice
      invoiceIsReady={invoicePdf !== ''}
      onClick={() => {
        if(invoicePdf === ''){
          return;
        }
        setLoader(true);
        handleClick();
      }}
    >
      View Invoice
      {loader && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}
    </ViewInvoice>
  );
};

export default FeeSendFormViewInvoice;

const ViewInvoice = styled.div<{ invoiceIsReady: boolean }>`
  text-decoration: underline;
  color: ${({ invoiceIsReady }) => (invoiceIsReady ? 'blue' : '#ccc')};
  cursor: ${({ invoiceIsReady }) => (invoiceIsReady ? 'pointer' : 'inherit')};
  display: flex;
  justify-content: center;
  position: relative;
`;

const LoaderWrapper = styled.div`
  zoom: 0.5;
  position: absolute;
  right: 582px;
`;

const useDebounce = (callback, delay) => {
  const timeoutRef = useRef(null);

  const debouncedCallback = useCallback(
    (...args) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay],
  );

  return debouncedCallback;
};
