import React, { FC, useEffect, useRef, useState, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../../../../../redux/root';
import { loadDocumentsData } from '../DocumentsTable';
import ProgressBar from '../ProgressBar';
import DetailsHeaderComponent from '../../../Common/DetailsHeaderComponent';
import FilesSection from './FilesSection';
import AvailableActions from './AvailableActions';
import { Logo_Sky } from '../../../../../../constants/menu';
import ReviewComments from './ReviewComments/ReviewComments';
import { setOpenedTabIndex, setPermitDocuments, setSelectedDocument } from '../../../../../../redux/slices/documentsSlice';
import { setShowDrawerContent } from '../../../../../../redux/slices/generalSlice';
import { DocumentFileItem } from '../../../../types';
import { sendClientDocument } from '../../../../../../api/document';
import SendButtonBar from '../SendButtonBar';

type Props = {
  onPermitItemSelected: Function;
};
const DocumentsItems: FC<Props> = ({ onPermitItemSelected }) => {
  const permitDocuments = useSelector((s: RootState) => s.documents.PermitDocuments);
  const OpenedTabIndex = useSelector((s: RootState) => s.documents.OpenedTabIndex);
  const vocabulary = useSelector((s: RootState) => s.general.vocabulary);
  const ref1 = useRef<HTMLDivElement>();
  const ref2 = useRef<HTMLDivElement>();
  const ref3 = useRef<HTMLDivElement>();
  const dispatch = useDispatch();
  const [showError, setShowError] = useState<boolean>(false);
  const [errorids, setErrorIds] = useState([]);
  const selectedDocument = useSelector((s: RootState) => s.documents.SelectedDocument);
  const selectedDocumentPermit = useSelector((s: RootState) => s.documents.SelectedDocumentPermit);
  const showDrawerContent = useSelector((s: RootState) => s.general.showDrawerContent);
  useEffect(() => {
    loadingDocData();
  }, [permitDocuments, selectedDocument]);
  useEffect(() => {
    ref1.current.scrollTop = 0;
    ref2.current.scrollTop = 0;
    ref3.current.scrollTop = 0;
  }, [OpenedTabIndex]);
  useEffect(() => {
    if (showDrawerContent) {
      dispatch(setSelectedDocument({} as DocumentFileItem));
      dispatch(setSelectedDocument({} as DocumentFileItem));
    }
  }, [showDrawerContent]);

  const loadingDocData = (forceLoad?: boolean) => {
    if (forceLoad || (permitDocuments.length === 0 && Object.keys(selectedDocument).length > 0)) {
      loadDocumentsData(selectedDocument, dispatch, vocabulary);
    }
  };

  return (
    <Wrapper>
      <DetailsHeaderComponent names={selectedDocumentPermit.namePermitName} address={selectedDocumentPermit.address} />
      <ProgressBar />
      <div>
        <SendButtonBar setShowError={setShowError} setErrorIds={setErrorIds} />
        <ThreeColumns>
          <Section ref={ref1} boxIndex={1} currIndex={OpenedTabIndex} onClick={() => dispatch(setOpenedTabIndex(1))}>
            <div>
              <DocumentsButton>
                <SubHeader>Documents ({permitDocuments.length})</SubHeader>
              </DocumentsButton>
            </div>
            <SectionBody>
              <FilesSection />
            </SectionBody>
          </Section>
          <Section ref={ref2} boxIndex={2} currIndex={OpenedTabIndex} onClick={() => dispatch(setOpenedTabIndex(2))}>
            <SubHeader>Available Actions</SubHeader>
            <SectionBody>
              <AvailableActions />
            </SectionBody>
          </Section>
          <Section ref={ref3} boxIndex={3} currIndex={OpenedTabIndex} onClick={() => dispatch(setOpenedTabIndex(3))}>
            <SectionBody>
              <ReviewComments errorids={errorids} setErrorIds={setErrorIds} />
            </SectionBody>
          </Section>
        </ThreeColumns>
      </div>
    </Wrapper>
  );
};

export default DocumentsItems;

export const SubHeader = styled.div`
  color: ${Logo_Sky};
  font-size: 18px;
  margin-left: 5px;
  font-weight: bold;
  margin-bottom: 7px;
  border-bottom: 2px solid;
  width: fit-content;
`;

const Section = styled.div<{ boxIndex: number; currIndex: number }>`
  padding-left: 10px;
  padding-top: 10px;
  width: 100%;
  height: 52vh;
  background-color: rgba(200, 200, 200, 0.2);
  border-right: ${({ boxIndex }) => (boxIndex === 3 ? '' : '1px solid grey')};
  overflow: auto;
  transition: height 0.4s ease-in-out;
  @media only screen and (max-width: 540px) {
    height: ${({ boxIndex, currIndex }) => {
      if (boxIndex === currIndex) {
        return '306px';
      }
      return '43px';
    }};
    overflow: ${({ boxIndex, currIndex }) => {
      if (boxIndex === currIndex) {
        return 'auto';
      }
      return 'hidden';
    }};
    border: 1px solid grey; //306px  overflow:auto
  }
`;

const SectionBody = styled.div`
  height: 80%;
  overflow: auto;
  /* height: 100%;
  @media only screen and (max-width: 540px) {
    height: 0;
    overflow: hidden;
  } */
`;

const ThreeColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  overflow: hidden;
  @media only screen and (max-width: 540px) {
    grid-template-columns: 1fr;
  }
`;
const Wrapper = styled.div`
  margin: 50px;
  margin-top: 5px;
  overflow: auto;
  display: grid;
  grid-template-rows: 43px 4vh 106px 1fr;
  @media only screen and (max-width: 840px) {
    margin: 3px;
    grid-template-rows: 55px 84px 120px 1fr;
  }
`;
export const PaperWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 50px;
  overflow: auto;
  max-height: 90%;
  @media only screen and (max-width: 840px) {
    grid-template-columns: 1fr;
    margin: 10px;
    margin-top: 50px;
  }
`;

const DocumentsButton = styled.div`
  display: flex;
  gap: 40px;
  @media only screen and (max-width: 840px) {
    flex-direction: column;
  }
`;
