import { MenuItem, Select } from '@material-ui/core';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useUpdateDocumentStatusMutation } from '../../../redux/rtkQuery/apiSlice';
import { DocumentStatusType } from '../../../modules/clientModule/types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/root';
import { setCheckedBoxes } from '../../../redux/slices/documentsSlice';

const StatusDropdown = ({ voca, refetch }) => {
  const [updateDocumentStatus] = useUpdateDocumentStatusMutation();
  const dispatch = useDispatch();
  const mcheckedBoxes = useSelector((s:RootState) => s.documents.mcheckedBoxes);
  const onChange = async e => {
    const ids = Object.keys(mcheckedBoxes).reduce((idArr,item)=>{
      if(mcheckedBoxes[item]){
        idArr.push(item);
      }
      return idArr;
    },[]).join(',');
    const formData = new FormData();
    formData.append('document_ids',ids);
    formData.append('status', e.target.value);
  
    await updateDocumentStatus(formData);
    await refetch();
    dispatch(setCheckedBoxes({}));
  }
  return (
    <Wrapper>
      <Select defaultValue={100} id="named-select" name="demo-select" onChange={onChange}>
        <MenuItem value={100}>Status</MenuItem>
        {voca &&
          voca.results.map((item, index) => (
            <MenuItem key={index} value={item.title}>
              {item.title}
            </MenuItem>
          ))}
      </Select>
    </Wrapper>
  );
};

export default StatusDropdown;

const Wrapper = styled.div`
  position: relative;
  top: -6px;
  left: 20px;
  .MuiInputBase-root{
    padding-left: 5px;
  }
  .MuiInput-underline:before {
    border: none;
  }
`;
