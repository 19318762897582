import * as React from 'react';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import {
  InputTextWithLabel,
  InputBaseContainer,
  InputSelectWithLabel,
  CheckboxWithLabel,
  ButtonWithLoader,
} from '../molecules';
import { FormServerError } from '../types';
import isYupFieldRequired from '../utils/isYupFieldRequired';

import transformServerErrorsToForm from '../utils/transformServerErrorsToForm';
import { getTrades } from '../api/trades';
import { GET_ALL_QUERY_PARAMS } from '../constants';
import useUserRole from '../hooks/useUserRole';
import { getFranchiseList } from '../api/franchise';
import {getReferenceCodesByID, ReferenceCodes, ReferenceCodesTableType} from "../api/referenceCodes";

const labels = {
  name: 'Name',
  refCode: 'Code',
  comments: 'Comment',
  trades: 'Trades',
  isActive: 'Active',
  isDefault: 'Set as default in new Branch',
};

const schema = yup.object().shape({
  name: yup.string().required().label(labels.name),
  refCode: yup.string().required().label(labels.refCode),
  comments: yup.string().required().label(labels.comments),
  trades: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      }),
    )
    .required()
    .typeError('Select a Trades from the list')
    .label(labels.trades),
  isActive: yup.boolean().required().label(labels.isActive),
  isDefault: yup.boolean().label(labels.isDefault),
});

const ReferenceCodesForm = ({
  onSubmit,
  modalError,
  initialValues,
  addButtonText = 'Add new item',
  updateButtonText = 'Update',
  initialErrors,
}: Props) => {
  const {
    register,
    handleSubmit,
    control,
    errors,
    setError,
    setValue,
    formState,
  } = useForm<ReferenceCodes>({
    resolver: yupResolver(schema),
    defaultValues: initialValues || ({} as any),
  });

  const { isSuperAdmin } = useUserRole();

  const isDeleted = initialValues?.isDeleted;

  const [isSubmitting, setIsSubmitting] = React.useState<
    boolean
  >(false);

  const [isLoading, setIsLoading] = React.useState<boolean>(
    false,
  );

  React.useEffect(() => {
    if (isSuperAdmin) {
      setIsLoading(true);

      Promise.all([
        getFranchiseList({
          ...GET_ALL_QUERY_PARAMS,
          ordering: 'name',
        }),
      ])
        .then(([franchisesResponse]) => {
          const franchises = franchisesResponse.data.results.map(
            item => item.id,
          );

          setValue(
            'franchise',
            franchises.filter(Boolean).length
              ? franchises
              : undefined,
          );
        })
        .catch(() => {})
        .finally(() => setIsLoading(false));
    }
  }, []);

  React.useEffect(() => {
    if (
      formState.isSubmitting &&
      !Object.keys(errors).length
    ) {
      setIsSubmitting(formState.isSubmitting);
    }
  }, [formState]);


  const [tradesData, setTradesData] = React.useState([]);
  const [
    referenceCodeData,
    setReferenceCodeData,
  ] = React.useState(null);

  React.useEffect(() => {
    setIsLoading(true);

    Promise.all([
      getTrades({
        ...GET_ALL_QUERY_PARAMS,
        ordering: 'name',
      }),
    ])
      .then(([tradesResponse]) => {

        setTradesData(tradesResponse.data.results);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }, []);

  React.useEffect(() => {
    if (initialValues?.id) {
      getReferenceCodesByID(initialValues.id)
        .then(response =>
          setReferenceCodeData(response.data))
        .catch(() => {});
    }
  }, [initialValues]);

  React.useEffect(() => {
    if (referenceCodeData && tradesData.length) {
      // setValue('ref_code',referenceCodeData.ref_code);
      setValue(
        'trades',
        tradesData.filter(item =>
          referenceCodeData.trades.includes(item.id)),
      );
    }
  }, [referenceCodeData, tradesData]);

  React.useEffect(() => {
    if (initialErrors) {
      transformServerErrorsToForm<ReferenceCodes>(
        initialErrors,
      ).forEach(({ name, types }) =>
        setError(name, { types }));
    }
  }, [initialErrors]);

  React.useEffect(() => {
    if (Object.keys(errors).length) {
      setIsSubmitting(false);
    }
  }, [errors, initialErrors]);

  const middleware = (

    submitFn: (data: ReferenceCodes) => void,
  ) => (data: ReferenceCodes) => {
    submitFn({
      ...data,
      id: initialValues?.id,
      isDeleted: initialValues?.isDeleted,
      trades:
        data.trades && data.trades.map(item => item.id),
      franchise: data.franchise
        ? (data.franchise as string)
            .split(',')
            .map(parseFloat)
        : undefined,
    });

  };

  return (
    <Container
      disabled={
        isLoading ||
        isDeleted ||
        (isSubmitting && !modalError)
      }
    >
      <FormContainer
        autoComplete="off"
        aria-autocomplete="none"
        onSubmit={handleSubmit(middleware(onSubmit))}
      >
        <InputBaseContainer>
          <InputTextWithLabel
            name="name"
            error={errors.name}
            inputRef={register}
            label={labels.name}
            isRequired={isYupFieldRequired('name', schema)}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <InputTextWithLabel
            name="refCode"
            error={errors.refCode}
            inputRef={register}
            label={labels.refCode}
            isRequired={isYupFieldRequired('refCode', schema)}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <InputTextWithLabel
            name="comments"
            error={errors.comments}
            inputRef={register}
            label={labels.comments}
            isRequired={isYupFieldRequired('comments', schema)}
          />
        </InputBaseContainer>
         <InputBaseContainer>
          <Controller
            name="trades"
            defaultValue=""
            control={control}
            render={props => (
              <InputSelectWithLabel
                isMulti
                placeholder=""
                isLoading={isLoading}
                isDisabled={isLoading}
                label={labels.trades}
                error={errors.trades}
                getOptionValue={(item: any) =>
                  item.id || Math.random()
                }
                getOptionLabel={(item: any) => item.name}
                isRequired={isYupFieldRequired(
                  'trades',
                  schema,
                )}
                options={tradesData}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </InputBaseContainer>
        <InputBaseContainer>
          <Controller
            name="isActive"
            control={control}
            valueName="checked"
            defaultValue={
              initialValues ? initialValues.isActive : true
            }
            render={({ onChange, onBlur, value }) => (
              <CheckboxWithLabel
                error={errors.isActive}
                label={labels.isActive}
                checked={value}
                onBlur={onBlur}
                onChange={(e, flag) => onChange(flag)}
              />
            )}
          />
        </InputBaseContainer>
        {isSuperAdmin && (
          <InputBaseContainer>
            <Controller
              name="isDefault"
              control={control}
              valueName="checked"
              defaultValue={
                initialValues?.isDefault ?? true
              }
              render={({ onChange, onBlur, value }) => (
                <CheckboxWithLabel
                  error={errors.isDefault}
                  label={labels.isDefault}
                  checked={value}
                  onBlur={onBlur}
                  onChange={(e, flag) => onChange(flag)}
                />
              )}
            />
          </InputBaseContainer>
        )}

        <input
          type="hidden"
          name="franchise"
          ref={register}
        />

        {!isDeleted && (
          <ButtonWithLoader
            disabled={isSubmitting && !modalError}
            loading={isSubmitting && !modalError}
            type="submit"
          >
            {initialValues
              ? updateButtonText
              : addButtonText}
          </ButtonWithLoader>
        )}
      </FormContainer>
    </Container>
  );
};

const Container = styled.fieldset`
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  border: none;
  text-align: center;
`;

const FormContainer = styled.form`
  width: 100%;

  .MuiButton-root {
    margin-top: 24px;
  }
`;

interface Props {
  addButtonText?: string;
  updateButtonText?: string;
  initialValues?: ReferenceCodesTableType;
  onSubmit: (data: ReferenceCodes) => void;
  initialErrors?: FormServerError<ReferenceCodes>;
  modalError: boolean;
}

export default ReferenceCodesForm;
