import tableColumnsFormatter from '../../utils/tableColumnsFormatter';

const columnsAll = column => {
  if (['permitContacts'].includes(column)) {
    return null;
  }

  switch (column) {
    default:
      return tableColumnsFormatter(column);
  }
};

const columnsPermit = column => {
  if (
    [
      'permitContacts',
      'id',
      'amount',
      'buildingTitle',
      'completedAt',
      'contractorEmail',
      'createdAt',
      'description',
      'info',
      'inspectionsCount',
      'isCompleted',
      'squareFeet',
      'storageBox',
      'supportingDocuments',
      'workTitle',
      'isDeleted'
      'projectId',
      'tickets',
      'inspections'
    ].includes(column)
  ) {
    return null;
  }

  switch (column) {
    case 'name':
      return 'Permit Name';
    case 'statusTitle':
      return 'Permit Status';
    case 'serviceTypes':
      return 'Permit Type';
    case 'jurisdictionName':
      return 'Jurisdiction';
    case 'number':
      return 'Permit Number';
    case 'managerName':
      return 'Project Manager';
    default:
      return tableColumnsFormatter(column);
  }
};
const columnsInvoice = column => {
  if (
    [
      'creator',
      'amount',
      'feeSettings',
      'permitContacts',
      'id',
      'invoiceId',
      'isPaidManual',
      'lastComment',
      'quantity',
      'permitId',
      'createdAt',
      'createdBy',
      'invoicePdf',
      'notes',
      'fullInvoiceNumber'
    ].includes(column)
  ) {
    return null;
  }

  switch (column) {
    case 'name':
      return 'Fee Name';
    case 'total':
      return 'Total Amount';
    default:
      return tableColumnsFormatter(column);
  }
};

const columnsPermitOrder = [
  'projectName',
  'name',
  'address',
  'managerName',
  'serviceTypes',
  'jurisdictionName',
  'number',
  'statusTitle',
];

const columnsInvoiceOrder = [
  'projectName',
  'permitName',
  'invoiceNumber',
  'name',
  'total',
  'status',
  'dateInvoiced',
  'datePaid'
];
const columnsClients = column => {
  if (
    [
      'avatar',
      'city',
      'clientType',
      'createdAt',
      'franchise',
      'franchiseNames',
      'isActive',
      'isDeleted',
      'isEpsuperadmin',
      'isInvitationSent',
      'licenses',
      'mobilePhoneNumber',
      'parentCompany',
      'prNumber',
      'state',
      'street',
      'trade',
      'tradesNames',
      'userRole',
      'zipCode',
      'id',
      'firstName',
      'lastName',
      'companyName',
      'permitStatuses',
      'permitNames',
      'contactTypes'
    ].includes(column)
  ) {
    return null;
  }

  switch (column) {
    case 'phoneNumber':
      return 'Phone';
    default:
      return tableColumnsFormatter(column);
  }
};

const columnsClientsOrder = [
  'name',
  'phoneNumber',
  'email',
  'address',
  'contactTypes',
  'permitNames',
  'permitStatuses',
];

const columnsInspectionsInspector = column => {
  if (
    [
      'assignedTo',
      'followUp',
      'information',
      'isDeleted',
      'isEmergency',
      'period',
      'report',
      'trades',
      'tradesNames',
      'id',
      'permit',
      'contact',
      'inspector',
      'permitName',
      'phoneNumber',
      'cellPhone',
      'contactId',
      'contactPhoneNumber',
      'contactMobilePhoneNumber',
      'contactFirstName',
      'contactLastName',
    ].includes(column)
  ) {
    return null;
  }

  switch (column) {
    case 'inspectorEmail':
      return 'Inspector';
    case 'contactEmail':
      return 'Activity Contact';
    case 'titleName':
      return 'Activity Title';
    case 'statusName':
      return 'Activity Status';
    case 'isCompleted':
      return 'Completed';
    case 'assignedToEmail':
      return 'Assigned To';
    case 'projectPermitName':
      return 'Project - Permit'
    default:
      return tableColumnsFormatter(column);
  }
};

const columnsInspections = column => {
  if (
    [
      'assignedTo',
      'followUp',
      'information',
      'isDeleted',
      'isEmergency',
      'period',
      'report',
      'trades',
      'tradesNames',
      'id',
      'permit',
      'contact',
      'inspector',
      'projectPermitName',
      'phoneNumber',
      'cellPhone',
      'contactId',
      'contactFirstName',
      'contactLastName',
      'contactPhoneNumber',
      'contactMobilePhoneNumber',
      'contactEmail',
    ].includes(column)
  ) {
    return null;
  }

  switch (column) {
    case 'inspectorEmail':
      return 'Inspector';
    case 'activityContact':
      return 'Activity Contact';
    case 'titleName':
      return 'Activity Title';
    case 'statusName':
      return 'Activity Status';
    case 'isCompleted':
      return 'Completed';
    default:
      return tableColumnsFormatter(column);
  }
};

const columnsInspectionsOrderInspector = [
  'projectPermitName',
  'inspectionType',
  'inspectorEmail',
  'inspectionDate',
  'titleName',
  'statusName',
  'contactEmail',
  'isCompleted',
];

const columnsInspectionsOrder = [
  'permitName',
  'inspectionType',
  'inspectorEmail',
  'inspectionDate',
  'titleName',
  'statusName',
  'activityContact',
  'isCompleted',
];

const columnsEmployee = column => {
  if (
    [
      'avatar',
      'city',
      'clientType',
      'createdAt',
      'franchise',
      'franchiseNames',
      'isActive',
      'isDeleted',
      'isEpsuperadmin',
      'isInvitationSent',
      'licenses',
      'mobilePhoneNumber',
      'parentCompany',
      'prNumber',
      'state',
      'street',
      'trade',
      'tradesNames',
      'zipCode',
      'id',
      'companyName',
      'contactTypes',
      'address',
      'permitStatuses',
      'permitNames',
    ].includes(column)
  ) {
    return null;
  }

  switch (column) {
    case 'phoneNumber':
      return 'Phone';
    case 'userRole':
      return 'Role';
    default:
      return tableColumnsFormatter(column);
  }
};

const columnsEmployeeOrder = [
  'firstName',
  'lastName',
  'email',
  'phoneNumber',
  'userRole',
];

const columnsManager = column => {
  if (
    [
      'permitContacts',
      'id',
      'amount',
      'buildingTitle',
      'completedAt',
      'contractorEmail',
      'createdAt',
      'description',
      'info',
      'inspectionsCount',
      'isCompleted',
      'squareFeet',
      'storageBox',
      'supportingDocuments',
      'workTitle',
    ].includes(column)
  ) {
    return null;
  }

  switch (column) {
    case 'name':
      return 'Permit Name';
    case 'statusTitle':
      return 'Permit Status';
    case 'serviceTypes':
      return 'Permit Type';
    case 'jurisdictionName':
      return 'Jurisdiction';
    case 'number':
      return 'Permit Number';
    case 'managerEmail':
      return 'Project Manager';
    default:
      return tableColumnsFormatter(column);
  }
};

const columnsManagerOrder = [
  'projectName',
  'name',
  'address',
  'managerEmail',
  'serviceTypes',
  'jurisdictionName',
  'number',
  'statusTitle',
];

const columnsActivity = column => {
  if (
    [
      'amount',
      'assignedTo',
      'attachments',
      'attachmentsNames',
      'contactEmail',
      'createdAt',
      'fees',
      'feesNames',
      'followUp',
      'id',
      'information',
      'inspectorEmail',
      'isCompleted',
      'isDeleted',
      'isEmergency',
      'reviewType',
      'projectPermitName',
      'jurisdictionName',
      'permit',
      'phoneNumber',
      'cellPhone',
      'contactId',
      'contactPhoneNumber',
      'contactMobilePhoneNumber',
      'name',
      'title',
      'contactFirstName',
      'contactLastName',
      'status',
      'projectId',
      'title',
      'status',
      'permitId',
      'completedAt',
      'completedBy',
      'assignedToFirstName',
      'assignedToLastName',
      'companyName',
      'activityTitleName',
      'activityTypeName',
      'projectName',
      'projectJurisdiction',
      'inspector',
      'lastComment',
      'feeNames',
      'conditionName',
      'selectedDocuments',
      'jurisdiction',
      'contactPhoneNumber',
      'contactMobilePhoneNumber',
      'contactFirstName',
      'contactLastName',
      'createdBy',
      'reviewTypeComment',
      'notes',
      'invoice'
    ].includes(column)
  ) {
    return null;
  }

  switch (column) {
    case 'titleName':
      return 'Activity Title';
    case 'statusName':
      return 'Activity Status';
    case 'permitNumber':
      return 'Permit Number'
    default:
      return tableColumnsFormatter(column);
  }
};

const columnsActivityInspector = column => {
  if (
    [
      'amount',
      'projectId',
      'title',
      'status',
      'permitId',
      'completedAt',
      'completedBy',
      'assignedToFirstName',
      'assignedToLastName',
      'companyName',
      'activityTitleName',
      'activityTypeName',
      'projectName',
      'projectJurisdiction',
      'inspector',
      'lastComment',
      'feeNames',
      'conditionName',
      'selectedDocuments',
      'attachments',
      'attachmentsNames',
      'contactEmail',
      'createdAt',
      'fees',
      'feesNames',
      'followUp',
      'id',
      'information',
      'inspectorEmail',
      'isCompleted',
      'isDeleted',
      'isEmergency',
      /*'reviewType',*/
      'permitName',
      'permit',
      'phoneNumber',
      'cellPhone',
      'contactId',
      'contact',
      'contactPhoneNumber',
      'contactMobilePhoneNumber',
      'contactFirstName',
      'contactLastName',
      'createdBy',
      'reviewTypeComment'
    ].includes(column)
  ) {
    return null;
  }

  switch (column) {
    case 'titleName':
      return 'Activity Title';
    case 'statusName':
      return 'Activity Status';
    case 'projectPermit':
      return 'Project - Permit'
    case 'jurisdictionName':
      return 'Jurisdiction'
    default:
      return tableColumnsFormatter(column);
  }
};

const columnsActivityOrder = [
  'permitName',
  'permitNumber',
  'activityType',
  'activityTitle',
  'statusName',
  'contact',
  'createdBy'
];

const columnsActivityOrderInspector = [
  'projectPermit',
  'jurisdiction',
  'permitNumber',
  'reviewType',
  'activityType',
  'activityTitle',
  'statusName',
  'assignedTo',
];

const columnsTickets = column => {
  if (
    [
      'creatorEmail',
      'isDeleted',
      'subject',
      'lastUpdated',
      'contact',
    ].includes(column)
  ) {
    return null;
  }

  switch (column) {
    case 'statusTitle':
      return 'Ticket Status';
    case 'id':
      return 'Ticket Number';
    case 'createdAt':
      return 'Creation Date';
    default:
      return tableColumnsFormatter(column);
  }
};

const columnsTicketsOrder = [
  'permitName',
  'statusTitle',
  'description',
  'id',
  'lastUpdated',
];

const columnsAllOrder = [];

const setColumns = (tab, mode, module?) => {
  if (tab === 'permitActivity') {
    if (module === 'inspector') {
      return mode === 'columns'
        ? columnsActivityInspector
        : columnsActivityOrderInspector;
    }
    else {
      return mode === 'columns'
        ? columnsActivity
        : columnsActivityOrder;
    }
  }
  if (tab === 'permit') {
    return mode === 'columns'
      ? columnsPermit
      : columnsPermitOrder;
  }
  if (tab === 'invoice') {
    return mode === 'columns'
      ? columnsInvoice
      : columnsInvoiceOrder;
  }
  if (tab === 'clients') {
    return mode === 'columns'
      ? columnsClients
      : columnsClientsOrder;
  }
  if (tab === 'employee') {
    return mode === 'columns'
      ? columnsEmployee
      : columnsEmployeeOrder;
  }
  if (tab === 'inspections') {
    if (module === 'inspector') {
      return mode === 'columns'
        ? columnsInspectionsInspector
        : columnsInspectionsOrderInspector;
    }
    else {
      return mode === 'columns'
        ? columnsInspections
        : columnsInspectionsOrder;
    }
  }
  if (tab === 'projectManager') {
    return mode === 'columns'
      ? columnsManager
      : columnsManagerOrder;
  }
  if (tab === 'tickets') {
    return mode === 'columns'
      ? columnsTickets
      : columnsTicketsOrder;
  }
  return mode === 'columns' ? columnsAll : columnsAllOrder;
};

export default setColumns;
