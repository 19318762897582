import React, { useEffect, useRef, useState } from 'react';
import ClientContainer from '../../ClientContainer';
import BannerStrip from '../../Common/Containers/BannerStrip';
import WrapperWithRightDrawer from '../../Common/Containers/WrapperWithRightDrawer/WrapperWithRightDrawer';
import StripeElements from './StripeElements';
import { getStripeClientSecret } from '../../../../../api/fees';
import { useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { RootState } from '../../../../../redux/root';
import _ from 'lodash';

const PayInvoice = () => {
  const [clientSecret, setClientSecret] = useState<string>('');
  const [stripePublishableKey, setStripePublishableKey] = useState<string>('');
  const stripePromise = useRef<any>();
  const selectedFranchise = useSelector((s: RootState) => s.userAuth.info.selectedFranchise);
  const info = useSelector((s: RootState) => s.userAuth.info);

  useEffect(() => {
    const loadSecret = async () => {
      const payload = {
        permit_id: permit.id,
        amount: 100.34,
      };
      const result = await getStripeClientSecret(payload);
      //@ts-ignore
      setClientSecret(result.data.clientSecret);
    };
    loadSecret();
  }, []);

  useEffect(() => {
    if (info && info.selectedFranchise) {
      const publishableKey = _.get(info, `franchise[${info.selectedFranchise}].franchiseStripeSetting.stripePublishableKey`);
      if(publishableKey){
        const pk = 'pk_test_51NijsYBBCbXvlpFBE41uX58LX18vkZh6mi7MggD5U8wF4TCsC4EAZUZu4fphJxa7xMRD6jO5PEjYgn9xycauUDKV00uRMoRQDs';
        stripePromise.current = loadStripe(pk);
      }
      
  
    }
  }, [info]);
  return (
    <ClientContainer>
      <BannerStrip label="INVOICES"></BannerStrip>
      <WrapperWithRightDrawer>
        {clientSecret && stripePromise.current && <StripeElements clientSecret={clientSecret} stripePromise={stripePromise.current} />}
      </WrapperWithRightDrawer>
    </ClientContainer>
  );
};

export default PayInvoice;

const permit = {
  id: 15181,
  name: 'Sarasota Test # 2',
  description: 'Sarasota Test # 2',
  contractor: null,
  amount: '0.00',
  service_type: [17, 18, 19, 20, 21, 22, 63],
  work: 4,
  building: 1,
  created_at: '07/23/2024, 11:16:59 -0400',
  completed_at: null,
  permit_contacts: [
    {
      user: 1455,
      contact_type: 1,
      is_accounting: false,
    },
    {
      user: 105,
      contact_type: 1,
      is_accounting: false,
    },
    {
      user: 336,
      contact_type: 1,
      is_accounting: true,
    },
  ],
  manager: 1186,
  is_completed: false,
  inspections_count: 0,
  project: 15137,
  status: 23,
  info: '',
  storage_box: '0',
  number: '',
  square_feet: 0,
  expiration_date: null,
  permit_issued: true,
};
