import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import cards from '../../../../../resources/cards.jpg';
import checkout from '../../../../../resources/checkout.png';
import StripeInvoicesTable from './StripeInvoicesTable';
import { Button } from '@material-ui/core';
import { Loader } from '../../../../../atoms';

const CheckoutForm = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const stripe = useStripe();
  const elements = useElements();
  const queryParams = new URLSearchParams(window.location.search);
  const redirect = queryParams.get('redirect_status');
  //redirect_status=succeeded

  const handleSubmit = async event => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setLoading(true);
    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/client/invoices`,
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      setError(result.error.message || "Error occured, please check the log");
      console.log(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
    setLoading(false);
  };

  return (
    <>
      <PaymentForm onSubmit={handleSubmit}>
        <Controls>
          <Mask>
            <img src={cards} />
          </Mask>
          <PaymentWrapper>
            {/* <StripeInvoicesTable /> */}
            <div>
              <PaymentElement id="payment-element" />
            </div>
          </PaymentWrapper>
        </Controls>

        <Buttons>
          <Button variant="contained" type="submit" disabled={false}>
            Pay
          </Button>
          {loading && <Loader />}
          {error && <Errors>{error}</Errors>}
        </Buttons>
      </PaymentForm>
    </>
  );
};

export default CheckoutForm;

const PaymentForm = styled.form`
  margin: 50px;
  width: fit-content;
  label {
    font-weight: bold;
  }
`;

const PaymentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
`;

const Field = styled.div`
  margin-bottom: 20px;
  .StripeElement {
    border: 1px solid grey;
    border-radius: 6px;
    height: 30px;
    input {
      font-size: 30px;
    }
  }
`;

const Controls = styled.div`
  position: relative;
  border: 2px solid #ccc;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 4px;
  width: 308px;
`;

const Buttons = styled.div`
  display: flex;
  gap: 40px;
`;

const Mask = styled.div`
  position: absolute;
  width: 296px;
  height: 100px;
  top: 0px;
  left: 2px;
  z-index: 10;
  background-color: #fff;
  img {
    height: 35px;
    position: relative;
    top: 20px;
    left: 30px;
  }
`;

const Errors = styled.div`
  color: red;
  font-size: 18px;
  position: relative;
  top: 7px;
`;