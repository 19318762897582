import { uploadInspectionReport } from '../../../api/inspectionTypes';
import { createPermitInspectionsComment, getPermitInspections, updatePermitInspection } from '../../../api/inspections';
import { InspectionItemType } from '../../../modules/clientModule/types';
import {
  setDisabledGlobalSearch,
  setFormData,
  setModalOpen,
  setPdfReport,
  setSelectedInspection,
  setShowPopup,
} from '../../../redux/slices/inspectionsSlice';
import tableColumnsFormatter from '../../../utils/tableColumnsFormatter';

export const getDailyInspectionRecords = async params => {
  const { data } = await getPermitInspections(params);
  return data;
};

export const calculatePeriod = (item: any) => {
  if (item.period === null ||item.period === 2 || item.period === undefined || item.period === '') {
    return '';
  }

  return item.period === 0 ? 'AM' : 'PM';
};

export type Info = {
  label: string;
  value: string;
};
export const getInspectionReadOnlyInfo = (insp: InspectionItemType): Info[] => {
  return [
    {
      label: 'Project Name',
      value: insp.projectName,
    },
    {
      label: 'Permit Name',
      value: insp.permitName,
    },
    {
      label: 'Permit Address',
      value: insp.projectAddress,
    },
    {
      label: 'Jurisdiction',
      value: insp.projectJurisdiction,
    },
    {
      label: 'Project Manager',
      value: `${insp.managerFirstName} ${insp.managerLastName}`,
    },
    {
      label: 'Inspection Number',
      value: `${insp.id}`,
    },
  ];
};

export const persistInspectionData = async (selectedInspection, reportPdf) => {
  try {
    const formData = {
      id: selectedInspection.id,
      report: reportPdf,
      title: selectedInspection.title,
      inspection_type: selectedInspection.inspectionType,
      status: selectedInspection.status,
    };
    const result = await uploadInspectionReport(formData);
    return '';
  } catch (e) {
    console.log(e.message);
    return JSON.stringify(e);
  }
};

export const closeInspection = (dispatch): void => {
  dispatch(setShowPopup('none'));
  dispatch(setPdfReport(''));
  dispatch(setFormData({} as InspectionItemType));
  dispatch(setModalOpen(false));
  dispatch(setSelectedInspection({} as InspectionItemType));
  dispatch(setDisabledGlobalSearch(false));
};

export const createNewComment = async (inspectionId, newComment) => {
  if (newComment === '') {
    return;
  }
  const data = {
    text: newComment,
    id: inspectionId,
  };
  const result = await createPermitInspectionsComment(data);
  return result;
};

export const currencyFormatCents = (value, digits = 2) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: digits,
  }).format(value);
};

export const convertAgingDetailsData = (input: any) => {
  const CURRENT = 'current';
  const P1_30 = '130';
  const P31_60 = '3160';
  const P61_90 = '6190';
  const P91_over = '91AndOver';
  const records = input[0];
  const totals = input[1]['grandTotal'];

  const skeleton = [
    {
      name: CURRENT,
      label: 'current',
      records: [],
      totals: totals.ageBucketTotals[CURRENT]
    },
    {
      name: P1_30,
      label: '1-30',
      records: [],
      totals: totals.ageBucketTotals[P1_30]
    },
    {
      name: P31_60,
      label: '31-60',
      records: [],
      totals: totals.ageBucketTotals[P31_60]
    },
    {
      name: P61_90,
      label: '61-90',
      records: [],
      totals: totals.ageBucketTotals[P61_90]
    },
    {
      name: P91_over,
      label: '91 and over',
      records: [],
      totals: totals.ageBucketTotals[P91_over]
    },
  ];

  const pickData = (item:any, arr:any, sectionName: string) => {
    if (item.invoices && item.invoices[sectionName]) {
      const section = arr.find(a => a.name === sectionName);
      if (section && item.invoices[sectionName].invoice) {
        item.invoices[sectionName].invoice.forEach(rec =>  section.records.push(rec))
      }
    }
  };

  const data = Object.keys(records).reduce((arr, itemKey) => {
    const item = records[itemKey];
    
    pickData(item, arr, CURRENT);
    pickData(item, arr, P1_30);
    pickData(item, arr, P31_60);
    pickData(item, arr, P61_90);
    pickData(item, arr, P91_over);
 

    return skeleton;
  }, skeleton);

  //sorting the records
  const _sortedData = data.map( d => {
    d.records = d.records.sort((a,b) => {
      if(new Date(a.date) > new Date(b.date)) return 1;
      if(new Date(b.date) > new Date(a.date)) return -1;
      return 0;
    })
    return d;
  })
  return _sortedData;
};

export const getFieldsForInspection = (selectedInspection: InspectionItemType, formData:any): any => {
  const {
    assignedTo,
    completedAt,
    contact,
    followUp,
    id,
    inspectionDate,
    inspectionType,
    inspector,
    cellPhone,
    // mobilePhoneNumber,
    permit,
    phoneNumber,
    title,
  } = selectedInspection;
  const payload = {
    activityTitle:  getFieldId(formData.activityTitle),
    assignedTo,
    completedAt,
    completedBy:selectedInspection.createdById,
    conditionInspectionPrompt: false,
    contact: getFieldId(formData.activityContact),
    followUp,
    id,
    information:formData.information,
    inspectionDate,
    inspectionPrompt: false,
    inspectionType:  getFieldId(formData.inspectionType),
    inspector,
    isCompleted: false,
    isEmergency: false,
    mobilePhoneNumber: cellPhone,
    permit,
    phoneNumber,
    status:  getFieldId(formData.activityStatus),
    title:  getFieldId(formData.activityTitle),
    toActivityContact:true,
    toJurisdiction:true,
    toProjectContacts:true,
    period:  getFieldId(formData.period),
  };
  return payload;
}

const getFieldId = (val) => {
  if(!val) {
    return undefined;
  }
  return val.id;
}