export enum ROUTES {
  ROOT = '/',
  LOG_IN = '/login',
  DASHBOARD = '/dashboard',
  ACCOUNT = '/account',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/password-reset-confirm',
  CHANGE_PASSWORD = '/account/change-password',
  CHANGE_EMAIL = '/account/change-email',
  SETTINGS = '/settings',
  SETTINGS_JURISDICTION = '/settings/jurisdiction',
  SETTINGS_TRADES = '/settings/trades',
  SETTINGS_MESSAGES = '/settings/messages',
  SETTINGS_DOCUMENTS = '/settings/documents',
  SETTINGS_FEES = '/settings/fees',
  SETTINGS_SCHEDULING = '/settings/scheduling',
  SETTINGS_ACTIVITIES = '/settings/activities',
  SETTINGS_INSPECTION_TYPE = '/settings/inspection-type',
  SETTINGS_REFERENCE_CODES = '/settings/reference-codes',
  SETTINGS_CONTACT_TYPE = '/settings/contact-type',
  SETTINGS_REPORT_SETUP = '/settings/report-setup',
  SETTINGS_FORM_SETUP = '/settings/form-setup',
  SETTINGS_REPORT_ACTIVITIES = '/settings/activities',
  SETTINGS_VOCABULARY = '/settings/vocabulary',
  REPORTS = '/reports',
  REPORTS_SALES = '/reports/sales',
  PROJECTS = '/projects',
  CONTACTS = '/contacts',
  FRANCHISE = '/branch',
  FRANCHISE_CONFIGURATION = '/branch/branch-configuration',
  FRANCHISE_CONFIGURATION_JURISDICTION = '/branch/branch-configuration/jurisdiction',
  FRANCHISE_CONFIGURATION_USERS = '/branch/branch-configuration/users',
  FRANCHISE_CONFIGURATION_STRIPE = '/branch/branch-configuration/stripe',
  USERS = '/users',
  SEARCH = '/search',
  INVOICES = '/invoices',
  PERMIT = '/permit/:projectId/:permitId',
  ALL_ACTIVITIES = '/all-activities',
  INSPECTIONS = '/inspections',
  REVIEWS = '/reviews',
  SEARCH_INSPECTOR = '/search-inspector'
}

export const HOMEPAGE = ROUTES.DASHBOARD;
