import React from 'react';
import ClientContainer from '../../ClientContainer';
import BannerStrip from '../../Common/Containers/BannerStrip';
import WrapperWithRightDrawer from '../../Common/Containers/WrapperWithRightDrawer/WrapperWithRightDrawer';
import Drawer from '../../Common/Containers/WrapperWithRightDrawer/Drawer';
import DrawerContent from '../../Common/Containers/DrawerContent';
import InvoicesItems from './InvoicesItems';
import useCloseDrawers from '../../../hooks/useCloseDrawers';
import useShowDetailsFromDashboard from '../../../hooks/useShowDetailsFromDashboard';
import InvoicesTable from './InvoicesTable/InvoiceTable';
import InvoicePaidSuccessPopup from './InvoicePaidSuccessPopup';

const Invoices = () => {
  useCloseDrawers();
  useShowDetailsFromDashboard();
  const queryParams = new URLSearchParams(window.location.search);
  const value = queryParams.get("redirect_status") || "";

  return (
    <ClientContainer>
      {value === 'succeeded' && <InvoicePaidSuccessPopup isSuccess={value === 'succeeded'} />}
      <BannerStrip label="INVOICES"></BannerStrip>
      <WrapperWithRightDrawer>
        <InvoicesTable />
        <Drawer>
          <DrawerContent>
            <InvoicesItems />
          </DrawerContent>
        </Drawer>
      </WrapperWithRightDrawer>
    </ClientContainer>
  );
};

export default Invoices;
