import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/root';
import { ROUTES } from '../components/Routes/ClientRoutes';
import { onConditionSelectedWithDispatch } from '../components/pages/Conditions/ConditionsTable';
import { setSelectedPermit } from '../../../redux/slices/inspectionsSlice';
import { setSelectedPermitTicket } from '../../../redux/slices/ticketsSlice';
import { setSelectedDocumentPermit } from '../../../redux/slices/documentsSlice';

export type ProjectViewItem = {
    label: string;
    qty: number;
    route: string;
}


const useProjectsViewItems = () => {
    const initialData: ProjectViewItem[] = [
        { label: 'Documents', qty: 0, route: `${ROUTES.DOCUMENTS}?openDrawer=true` },
        { label: 'Inspections', qty: 0, route: `${ROUTES.INSPECTIONS}?openDrawer=true` },
        { label: 'Conditions', qty: 0, route: `${ROUTES.CONDITIONS}?openDrawer=true` },
        { label: 'Fees', qty: 0, route: `${ROUTES.INVOICES}?openDrawer=true` },
        { label: 'Tickets', qty: 0, route: `${ROUTES.TICKETS}?openDrawer=true` },
      ];
  const [linkedItems, setLinkedItems] = useState(initialData);
  const dispatch = useDispatch();
  const selectedPermit = useSelector((state: RootState) => state.projectsSlice.selectedPermit);
  const permitInspectionsList = useSelector((state: RootState) => state.inspections.permitInspectionsList);
  const AllPermitsWithConditions = useSelector((state: RootState) => state.conditions.AllPermitsWithConditions);
  const AllPermitsWithDocs = useSelector((state: RootState) => state.documents.AllPermitsWithDocs);
  const inspectionInvoicesList = useSelector((state: RootState) => state.fees.inspectionInvoicesList);
  const permitTicketsList = useSelector((state: RootState) => state.tickets.permitTicketsList);
  const showDrawerContent = useSelector((s: RootState) => s.general.showDrawerContent);

  useEffect(() => {
    if(!showDrawerContent) {
      setLinkedItems(initialData);
      return;
    }
    const condition = AllPermitsWithConditions.find(pi => pi.permitId == selectedPermit.permitId);
    const inspection = permitInspectionsList.find(pi => pi.permitId == selectedPermit.permitId);
    const document = AllPermitsWithDocs.find(pi => pi.permitId == selectedPermit.permitId);
    const fee = inspectionInvoicesList.find(pi => pi.permitId == selectedPermit.permitId);
    const ticket = permitTicketsList.find(pi => pi.permitId == selectedPermit.permitId);

    const _items = linkedItems.map(item => {
      if(item.label === 'Conditions' && condition) {
        onConditionSelectedWithDispatch(item,dispatch);        
        return {
          ...item,
          qty:condition.quantity
        }
      }
      if(item.label === 'Inspections' && inspection ) {
        //@ts-ignore
        dispatch(setSelectedPermit(inspection));
        return {
          ...item,
          qty:inspection.quantity
        }
      }

      if(item.label === 'Documents' && document) {
        //@ts-ignore
        dispatch(setSelectedDocumentPermit(document));
        return {
          ...item,
          qty:document.quantity
        }
      }

      // if(item.label === 'Fees' && fee) {
      //   dispatch(setSelectedFee(fee));
      //   return {
      //     ...item,
      //     qty:fee.quantity
      //   }
      // }

      if(item.label === 'Tickets' && ticket) {
        dispatch(setSelectedPermitTicket(ticket))
        return {
          ...item,
          qty:ticket.quantity
        }
      }
      return item;
    })

    setLinkedItems(_items);
  },[showDrawerContent])
  return {selectedPermit, linkedItems};
};

export default useProjectsViewItems;
