import React, { FC, Fragment, useState } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { currency } from '../../../../utils';
import { CCFee, PHONE, TABLET } from '../StripeInvoicesTable';

const BigScreenTable: FC<any> = ({invoices}) => {
    
  return (
    <Table>
      <Header>Invoice</Header>
      <Header>Invoice Date</Header>
      <NoSmallScreen>
        <Header>Unpaid Amount</Header>
      </NoSmallScreen>
      <Header>Transaction Fee</Header>
      <Header>Amount to Pay</Header>
      {invoices.map((invoice, index) => {
        return (
          <Fragment key={index}>
            <Cell>{invoice.invoiceNumber}</Cell>
            {invoice && <Cell>{format(new Date(invoice.invoicedAt), 'yyyy-MM-dd')}</Cell>}

            <NoSmallScreen>
              <Cell>
                <Currency>{currency(invoice.total)}</Currency>
              </Cell>
            </NoSmallScreen>
            <Cell><Currency>{currency(invoice.total * CCFee)}</Currency></Cell>
            <Cell>
              <CellEditable>
                <Editable>
                  <Currency>{currency(invoice.total * (1 + CCFee))}</Currency>
                </Editable>
              </CellEditable>
            </Cell>
          </Fragment>
        );
      })}
    </Table>
  );
};

export default BigScreenTable;

export const Table = styled.div`
  display: grid;
  grid-template-columns: 200px 200px 180px 180px 1fr;
  grid-gap: 2px;
  background-color: rgb(180, 180, 180);
  width: 98%;
  border: 2px solid rgb(180, 180, 180);
  border-radius: 6px;
  @media only screen and (max-width: ${TABLET}) {
    grid-template-columns: 200px 200px 1fr;
  }
  @media only screen and (max-width: ${PHONE}) {
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr;
  }
`;

export const Cell = styled.div`
  height: 50px;
  background-color: #fff;
  font-weight: bold;
  padding-top: 16px;
  padding-left: 16px;
  font-size: 17px;
`;

export const Currency = styled.div`
  text-align: right;
  padding-right: 20px;
`;

export const Editable = styled.div`
  border: 2px solid #ccc;
  padding: 8px;
  border-radius: 8px;
  position: relative;
  top: -12px;
  width: 100%;
  margin-right: 10px;
  margin-left: 10px;
`;

export const CellEditable = styled.div`
  display: flex;
  width: 100%;
`;

export const NoSmallScreen = styled.div`
  @media only screen and (max-width: ${TABLET}) {
    display: none;
  }
`;

export const Header = styled.div`
  background-color: grey;
  color: white;
  padding: 20px;
  padding-bottom: 0px;
  font-size: 20px;
  @media only screen and (max-width: ${PHONE}) {
    font-size: 15px;
  }
`;
