import * as React from 'react';
import useTablePage from '../hooks/useTablePage';
import ModalDefaultDraggable from '../molecules/ModalDefaultDraggable';
import ModalInfo from '../molecules/ModalInfo';
import PermitDocumentForm from './PermitDocumentForm/PermitDocumentForm';
import { getVocabulariesDataList } from '../api/vocabulary';
import { GET_ALL_QUERY_PARAMS } from '../constants';
import { updatePermitDocument } from '../api/projectDocuments';

const DocumentsEditForm = ({
  initialValues,
  handleCloseInfoModal,
  permit,
  project,
  setInitialValues,
}: Props) => {    

    const [status,setStatus] = React.useState(null)
    
  const {
    modalSuccess,
    modalError,
    createOrUpdate,
    modalTitle,
    errors,
    values,
    setValues,
    modalErrorTitle,
    handleCloseModal,
    handleCloseModalInfo,
  } = useTablePage({
    updateEndpoint: updatePermitDocument,
  });

  React.useEffect(() => {
    if (initialValues) {
      setValues(initialValues);
    }
    getVocabulariesDataList({
        ...GET_ALL_QUERY_PARAMS,
        vocabularyEntity: 10,
      }).then(statusesResponse => setStatus(statusesResponse.data.results))
  }, [initialValues]);

  const handleCloseActivitiesModal = () => {
    if (!modalError && !modalSuccess) {
      setInitialValues(null);
      handleCloseModal();
    }
  };

  return (
    <>
      {values ? (
        <ModalDefaultDraggable
          title={modalTitle}
          onClose={handleCloseActivitiesModal}
        >
          {/* <PermitDocumentForm
            onSubmit={createOrUpdate}
            initialErrors={errors}
            modalError={modalError}
            addButtonText="Add Document"
            updateButtonText="Update Document"
            permit={permit}
            statuses={status}
            initialValues={values}
            isReadOnly={true}
          /> */}
          <PermitDocumentForm
            onSubmit={createOrUpdate}
            initialErrors={errors}
            modalError={modalError}
            addButtonText="Add Document"
            updateButtonText="Update Document"
            permit={permit}
            statuses={status}
            initialValues={values}
          />
        </ModalDefaultDraggable>
      ) : null}
      {(modalSuccess || modalError) && (
        <ModalInfo
          timeToClose={5000}
          isError={modalError}
          onClose={() =>
            handleCloseInfoModal(handleCloseModalInfo)
          }
          errorMsg={modalErrorTitle}
        />
      )}
    </>
  );
};

interface Props {
  initialValues: object;
  handleCloseInfoModal: (data: () => void) => void;
  permit: number;
  project: number;
  setInitialValues: (data: any) => void;
}

export default DocumentsEditForm;
