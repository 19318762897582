/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import {
  labels,
  schema,
} from './validation/formSetupValidation';
import { FormServerError } from '../types';
import { FormSetupType } from '../api/formSetup';
import {
  ButtonWithLoader,
  CheckboxWithLabel,
  InputBaseContainer,
  InputFileContainer,
  InputSelectWithLabel,
  InputTextWithLabel,
  TextAreaWithLabel,
  ModalDefaultDraggable,
} from '../molecules';
import isYupFieldRequired from '../utils/isYupFieldRequired';
import { getJurisdictions } from '../api/jurisdiction';
import { GET_ALL_QUERY_PARAMS } from '../constants';
import { getDocumentList } from '../api/document';
import FormSetupPlaceholders from '../organisms/FormSetupPlaceholders';
import transformServerErrorsToForm from '../utils/transformServerErrorsToForm';
import {
  createResult,
  getActiveFieldsFromMarkup,
} from '../utils/formSetupUtils';
import DocumentsInFormSetup from '../organisms/DocumentsInFormSetup';
import { getBase64 } from '../utils/isDevMode';

const formType = [
  { value: 0, title: 'Documents' },
  { value: 1, title: 'Reports' },
];

const reportScopeData = [
  { value: 0, title: 'Franchise Administrator' },
  { value: 1, title: 'Employee' },
  { value: 2, title: 'Inspector' },
  { value: 3, title: 'Client' },
];

const FormSetupForm = ({
  onSubmit,
  modalError,
  initialErrors,
  initialValues,
  setSelectedFontSize,
  selectedFontSize,
}: Props) => {
  const {
    register,
    handleSubmit,
    errors,
    setError,
    control,
    setValue,
    // formState,
    watch,
  } = useForm<FormSetupType>({
    resolver: yupResolver(schema),
    defaultValues: initialValues || ({} as FormSetupType),
  });
  const selectedJurisdictions = watch('jurisdictions');
  const selectedType = watch('type');
  const selectedFile = watch('file');
  const [isLoading, setIsLoading] = React.useState<boolean>(
    false,
  );
  const [
    IsUpdatePlaceholder,
    setIsUpdatePlaceholder,
  ] = React.useState(false);
  const [isPlaceholders, setPlaceholders] = React.useState<
    boolean
  >(false);
  const [markup, setMarkup] = React.useState([]);
  const [
    initialActiveFields,
    setInitialActiveFields,
  ] = React.useState([]);
  const [
    jurisdictionsData,
    setJurisdictionsData,
  ] = React.useState([]);
  const [docsData, setDocsData] = React.useState([]);
  const [localUrl, setLocalUrl] = React.useState('');
  const [fileName, setFileName] = React.useState('');

  React.useEffect(() => {
    if (selectedType?.value === 0) {
      setTimeout(() => {
        register('documents');
      }, 600);
    }
    if (selectedType?.value === 1) {
      setTimeout(() => {
        register('reportScope');
      }, 600);
    }
  }, [selectedType]);

  React.useEffect(() => {
    if (initialValues) {
      if (selectedType.title) {
        if (selectedType?.value !== initialValues.type) {
          setIsUpdatePlaceholder(true);
          setInitialActiveFields([]);
          setMarkup([]);
        }
        setValue('reportScope', null);
      }
    }
  }, [selectedType]);

  React.useEffect(() => {
    if (initialValues) {
      if (
        selectedJurisdictions?.length !==
        initialValues?.jurisdictions?.length
      ) {
        setIsUpdatePlaceholder(true);
        setInitialActiveFields([]);
        setMarkup([]);
      }
    }
  }, [selectedJurisdictions]);

  React.useEffect(() => {
    if (initialValues) {
      setFileName(initialValues.visibleFilename);
    }

    if (initialValues?.formMarkup?.length) {
      const result = [];
      initialValues?.formMarkup.forEach(field => {
        getActiveFieldsFromMarkup(
          field,
          ['fields'],
          result,
        );
        setInitialActiveFields([
          ...initialActiveFields,
          ...result,
        ]);
      });
      setMarkup(
        createResult(
          [...initialActiveFields, ...result],
          initialValues?.type === 0
            ? 'Permit Documents'
            : 'User',
        ),
      );
    }
  }, [initialValues]);

  React.useEffect(() => {
    if (markup?.length) {
      setIsUpdatePlaceholder(!markup);
    }
  }, [markup]);

  React.useEffect(() => {
    if (initialValues) {
      if (initialValues?.reportScope?.length) {
        const selectedScope = reportScopeData?.filter(el =>
          initialValues?.reportScope?.includes(el.value),
        );
        setValue('reportScope', selectedScope);
      }
      if (
        initialValues.jurisdictions &&
        jurisdictionsData?.length
      ) {
        const initialJurisdictions = jurisdictionsData.filter(
          el =>
            initialValues.jurisdictions.includes(el?.id),
        );
        setValue('jurisdictions', initialJurisdictions);
      }

      if (initialValues.type || initialValues.type === 0) {
        const initialType = formType.filter(
          el => el.value === initialValues.type,
        );
        if (initialType?.length) {
          setValue('type', initialType[0]);
        }
      }

      if (initialValues?.documents?.length) {
        getDocumentList({
          ...GET_ALL_QUERY_PARAMS,
          jurisdiction: selectedJurisdictions.join(','),
        })
          .then(docsResponse => {
            const filteredDocs = docsResponse.data.results
              .map(el => ({
                title: el?.requirement,
                value: el?.id,
                jurisdiction: el.jurisdiction,
              }))
              .filter(doc =>
                doc?.jurisdiction.every(jur =>
                  selectedJurisdictions.includes(jur),
                ))
              .filter(doc => {
                return initialValues?.documents?.includes(
                  doc?.value,
                );
              });
            setValue('documents', filteredDocs);
          })
          .catch(err => console.log(err))
          .finally(() => setIsLoading(false));
      }
    }
  }, [initialValues, jurisdictionsData]);

  React.useEffect(() => {
    if (initialErrors) {
      transformServerErrorsToForm(
        initialErrors,
      ).forEach(({ name, types }) =>
        setError(name, { types }));
    }
  }, [initialErrors]);

  React.useEffect(() => {
    setIsLoading(true);
    getJurisdictions(GET_ALL_QUERY_PARAMS)
      .then(jurisdictionsResponse => {
        setJurisdictionsData(
          jurisdictionsResponse.data.results,
        );
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  }, []);

  React.useEffect(() => {
    if (
      selectedJurisdictions?.length &&
      selectedType?.value === 0
    ) {
      setIsLoading(true);

      const jurisdictionIds = selectedJurisdictions
        ?.filter(jur => jur?.id)
        .map(el => el?.id);

      getDocumentList({
        ...GET_ALL_QUERY_PARAMS,
        jurisdiction: jurisdictionIds.join(','),
      })
        .then(docsResponse => {
          const filteredDocs = docsResponse.data.results
            .map(el => ({
              title: el?.requirement,
              value: el?.id,
              jurisdiction: el.jurisdiction,
            }))
            .filter(doc =>
              doc?.jurisdiction.every(jur =>
                jurisdictionIds.includes(jur),
              ));
          setDocsData(filteredDocs);
        })
        .catch(err => console.log(err))
        .finally(() => setIsLoading(false));
    } else {
      setDocsData([]);
    }
  }, [selectedJurisdictions, selectedType]);

  const middleware = (
    submitFn: (data: FormSetupType) => void,
  ) => (data: FormSetupType) => {
    submitFn({
      ...data,
      name: data.name,
      description: data.description,
      jurisdictions: data.jurisdictions
        ?.filter(el => el?.value !== '*')
        ?.map(jur => +jur.id),
      type: data.type?.value,
      documents: data.documents
        ?.filter(el => el?.value !== '*')
        ?.map(doc => +doc?.value),
      reportScope: data.reportScope
        ?.map(el => el?.value)
        ?.filter(el => el?.value !== '*'),
      formMarkup: JSON.stringify(markup),
      fontSize: selectedFontSize,
    });
  };

  const middlewareUpdate = (
    submitFn: (data: FormSetupType) => void,
  ) => (data: FormSetupType) => {
    // EA-15 EA-190
    // mritunjoy
    const newMarkup = markup.map((el) => {
      if(el.field === "Inspectedinspections") {
        return {...el, field: "Inspected inspections"}
      } else if(el.field === "Parentcompany") {
        return {...el, field: "Parent company"}
      } else {
        return el
      }
    })
    
    const documentsData = data.documents && data.documents[0]?.title
      ? data.documents
          ?.filter(el => el?.value !== '*')
          ?.map(doc => +doc?.value)
      : data.documents || [];
    const reportScope = data.reportScope
      ?.filter(el => el?.value !== '*')
      ?.map(el => el?.value);

    submitFn({
      id: initialValues.id,
      name: data.name,
      description: data.description,
      jurisdictions: data.jurisdictions
        ?.filter(el => el?.value !== '*')
        ?.map(jur => +jur.id),
      type: data.type?.value,
      documents: documentsData,
      reportScope,
      formMarkup: JSON.stringify(newMarkup),
      file:
        initialValues?.file === data.file
          ? null
          : data.file,
      isDefault: data.isDefault,
      fontSize: selectedFontSize,
    });
  };

  const handleFileChange = e => {
    if (e.target.files[0]) {
      getBase64(e.target.files[0], result =>
        setLocalUrl(result));
    }
    setFileName(e.target.files[0].name);
    setValue('file', e.target.files[0]);
  };

  const getErrorServer = () => {
    return {
      ...errors.documents,
      message: errors.documents.types.server0,
    };
  };

  return (
    <>
      <Container disabled={isLoading}>
        <FormContainer
          autoComplete="off"
          aria-autocomplete="none"
          onSubmit={handleSubmit(
            !initialValues
              ? middleware(onSubmit)
              : middlewareUpdate(onSubmit),
          )}
        >
          <InputBaseContainer>
            <InputTextWithLabel
              name="name"
              error={errors.name}
              inputRef={register as any}
              label={labels.name}
              isRequired={isYupFieldRequired(
                'name',
                schema,
              )}
            />
          </InputBaseContainer>
          <InputBaseContainer>
            <TextAreaWithLabel
              name="description"
              error={errors.description}
              label={labels.description}
              inputRef={register as any}
              isRequired={isYupFieldRequired(
                'description',
                schema,
              )}
            />
          </InputBaseContainer>
          <InputBaseContainer>
            <Controller
              name="jurisdictions"
              defaultValue=""
              control={control}
              render={props => (
                <InputSelectWithLabel
                  isMulti
                  selectAllActive
                  placeholder=""
                  isLoading={isLoading}
                  isDisabled={isLoading}
                  label={labels.jurisdictions}
                  error={
                    errors.jurisdictions ||
                    (errors.documents && getErrorServer())
                  }
                  getOptionValue={(item: any) => item.id}
                  getOptionLabel={(item: any) => item.name}
                  isRequired={isYupFieldRequired(
                    'jurisdictions',
                    schema,
                  )}
                  options={jurisdictionsData}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                />
              )}
            />
          </InputBaseContainer>
          <InputBaseContainer>
            <Controller
              name="type"
              defaultValue=""
              control={control}
              render={props => (
                <InputSelectWithLabel
                  placeholder=""
                  isLoading={isLoading}
                  isDisabled={isLoading}
                  label={labels.type}
                  error={errors.type && getErrorServer()}
                  getOptionValue={(item: any) => item.value}
                  getOptionLabel={(item: any) => item.title}
                  isRequired={isYupFieldRequired(
                    'type',
                    schema,
                  )}
                  options={formType as any}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                />
              )}
            />
          </InputBaseContainer>
          {selectedType?.value === 0 && !initialValues && (
            <InputBaseContainer>
              <Controller
                name="documents"
                defaultValue=""
                control={control}
                render={props => (
                  <InputSelectWithLabel
                    isMulti
                    selectAllActive
                    placeholder=""
                    isLoading={isLoading}
                    isDisabled={isLoading}
                    label={labels.documents}
                    error={
                      errors.documents && getErrorServer()
                    }
                    getOptionValue={(item: any) =>
                      item.value
                    }
                    getOptionLabel={(item: any) =>
                      item.title ? item.title : item.name
                    }
                    isRequired
                    options={docsData}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                  />
                )}
              />
            </InputBaseContainer>
          )}
          {selectedType?.value === 1 && (
            <InputBaseContainer>
              <Controller
                name="reportScope"
                defaultValue=""
                control={control}
                render={props => (
                  <InputSelectWithLabel
                    isMulti
                    selectAllActive
                    placeholder=""
                    isLoading={isLoading}
                    isDisabled={isLoading}
                    label={labels.reportScope}
                    error={errors.reportScope}
                    getOptionValue={(item: any) =>
                      item.value
                    }
                    getOptionLabel={(item: any) =>
                      item.title ? item.title : item.name
                    }
                    isRequired
                    options={reportScopeData as any}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                  />
                )}
              />
            </InputBaseContainer>
          )}
          <InputBaseContainer>
            <Controller
              name="file"
              control={control}
              defaultValue={null}
              render={() => (
                <>
                  <StyledButton htmlFor="file-upload">
                    Choose File...
                  </StyledButton>
                  <StyledInputFile
                    id="file-upload"
                    error={errors.file}
                    onChange={handleFileChange}
                    accept="application/pdf"
                    isRequired={
                      !initialValues
                        ? isYupFieldRequired('file', schema)
                        : false
                    }
                  />
                  <StyledFileName>
                    <span>File</span>: {fileName}
                  </StyledFileName>
                </>
              )}
            />
          </InputBaseContainer>
          <InputBaseContainer>
            <Controller
              name="isDefault"
              control={control}
              valueName="checked"
              defaultValue={
                initialValues?.isDefault ?? true
              }
              render={({ onChange, onBlur, value }) => (
                <CheckboxWithLabel
                  error={errors.isDefault}
                  label={labels.isDefault}
                  checked={value}
                  onBlur={onBlur}
                  onChange={(e, flag) => onChange(flag)}
                />
              )}
            />
          </InputBaseContainer>
          {initialValues?.type === 0 && (
            <DocumentsInFormSetup
              initialDocs={initialValues.documents}
            />
          )}

          <ButtonSection>
            {initialValues && IsUpdatePlaceholder && (
              <span
                style={{
                  color: 'red',
                  fontSize: '14px',
                  marginTop: '24px',
                }}
              >
                Please, set up placeholders
              </span>
            )}
            <ButtonWrapper>
              <ButtonWithLoader
                disabled={
                  modalError ||
                  !selectedFile ||
                  !selectedType
                }
                loading={modalError}
                type="button"
                onClick={() => setPlaceholders(true)}
              >
                {initialValues && !localUrl && markup.length
                  ? 'Update Placeholders'
                  : 'Set Placeholders'}
              </ButtonWithLoader>
            </ButtonWrapper>
            <ButtonWrapper>
              <ButtonWithLoader
                disabled={modalError || !markup?.length}
                loading={modalError}
                type="submit"
              >
                {initialValues ? 'Update' : 'Add'}
              </ButtonWithLoader>
            </ButtonWrapper>
          </ButtonSection>
        </FormContainer>
      </Container>
      {isPlaceholders && (
        <StyledModalDraggable
          onClose={() => setPlaceholders(false)}
          title="Set Placeholders"
          wide
        >
          <FormSetupPlaceholders
            url={localUrl}
            type={selectedType?.value}
            setPlaceholders={setPlaceholders}
            setMarkup={setMarkup}
            initialValues={initialValues}
            initialActiveFields={initialActiveFields}
            setSelectedFontSize={setSelectedFontSize}
          />
        </StyledModalDraggable>
      )}
    </>
  );
};

interface Props {
  addButtonText?: string;
  onSubmit: (data: FormSetupType) => void;
  setSelectedFontSize: (data: any) => void;
  initialErrors?: FormServerError<FormSetupType>;
  modalError: boolean;
  initialValues?: FormSetupType;
  selectedFontSize: number;
}

const Container = styled.fieldset`
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  border: none;
  text-align: center;
`;

const FormContainer = styled.form`
  width: 100%;

  .MuiButton-root {
    margin-top: 24px;
  }
`;

const StyledInputFile = styled(InputFileContainer)`
  display: none;
`;
const StyledButton = styled.label`
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
`;

const ButtonSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledFileName = styled.h5`
  margin: 4px;
  & span {
    color: var(--primary);
  }
`;

const ButtonWrapper = styled.div`
  margin-left: 10px;
`;

const StyledModalDraggable = styled(ModalDefaultDraggable)`
  height: 100%;
  overflow: inherit;
`;

export default FormSetupForm;
