import { api } from './api';

import { PaginatedList, PaginatedRequest } from '../types';
import { TradeType } from './trades';
import { FranchiseType } from './franchise';

export const getReferenceCodes = (
  params: PaginatedRequest,
) =>
    api.request<ReferenceCodesList>({
    method: 'GET',
    url: '/review_references/complex-views/with-related/',
    params,
  });

export const getReferenceCodeEditTypes = (
  params: PaginatedRequest,
) =>
  api.request<ReferenceCodesList>({
    method: 'GET',
    url: '/review_references/',
    params,
  });

// Getting the inspection type byt id
export const getReferenceCodesByID = (id: number) =>
  api.request<ReferenceCodes>({
    method: 'GET',
    url: `/review_references/${id}/`,
  });

export const createReferenceCodes = (
  data: ReferenceCodes,
) =>
  api.request<ReferenceCodes>({
    method: 'POST',
    url: '/review_references/',
    data,
  });

export const updateReferenceCodes = (
  data: ReferenceCodes,
) =>
  api.request<ReferenceCodes>({
    method: 'PATCH',
    url: `/review_references/${data.id}/`,
    data,
  });

export const deleteReferenceCodes = (id: number) =>
  api.request<ReferenceCodes>({
    method: 'DELETE',
    url: `/review_references/${id}/`,
  });

type ReferenceCodesList = PaginatedList<
  ReferenceCodesTableType
>;

export interface ReferenceCodesTableType {
  id: number;
  isActive: boolean;
  isDeleted: boolean;
  name: string;
  refCode: string;
  comments: string;
  tradeId: number;
  tradeName: string;
}

export interface ReferenceCodes {
  id: number;
  name: string;
  refCode: string;
  comments: string;
  isActive: boolean;
  isDefault?: boolean;
  isDeleted: boolean;
  trades: number[] | TradeType[];
  franchise: number[] | string | FranchiseType[];
}

