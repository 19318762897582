import React, { useState } from 'react';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import Download from '@material-ui/icons/GetApp';
import styled from 'styled-components';
import { deletePermitDocument, downloadAllPermitDocuments } from '../../../api/projectDocuments';
import { ModalDeleteConfirmation } from '../../../molecules';

const DocumentActions = ({row, handleEdit, refetch}) => {
  const [show, setShow] = useState(false);
  const handleDelete = (row) => {
    setShow(true);
  }

  const onConfirm = async () => {
    await deletePermitDocument(row.pk);
    await refetch();
    setShow(false);
  }

  const handleDownload = async () => {
    const result = await downloadAllPermitDocuments([row.pk]);
    const binaryData = atob(result.data.file);
    const uint8Array = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    const blob = new Blob([uint8Array], {type: 'application/zip'});
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'files.zip';
    link.click();
    
  }
  return (
    <Wrapper>
      {show && <ModalDeleteConfirmation onConfirm={onConfirm} onCancel={() => setShow(false)} >
  Confirm delete action for a Document's file
        </ModalDeleteConfirmation>}
      <Button onClick={e => handleEdit(row)}>
        <Edit />
      </Button>
      <Button onClick={e => handleDelete(row)}>
        <DeleteOutline />
      </Button>
      <Button>
        <Download onClick={e => handleDownload(row.pk)} />
      </Button>
    </Wrapper>
  );
};

export default DocumentActions;

const Wrapper = styled.div`
  width: 65px;
  display: flex;
  justify-content: space-between;
`;

const Button = styled.div`
  cursor: pointer;
  color: rgba(0, 0, 0, 0.54);
  &:hover {
    color: #0b6b9b;
  }
`;
