import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FeeItemType, PermitFeeItem } from '../../modules/clientModule/types';
import { InvoiceHeader, InvoicePage, ProjectPermitInvoiceType } from '../../Components/InvoiceTool/types';
import { INVOICES_TO_PAY_KEY, SELECTED_PERMIT_ID } from '../../settings';

export interface FeesState {
  inspectionInvoicesList: PermitFeeItem[];
  selectedProjectInvoice: ProjectPermitInvoiceType;
  selectedFee: PermitFeeItem;
  permitFees: FeeItemType[];
  invoiceHeader: InvoiceHeader;
  invoiceBody: InvoicePage[];
  invoicePdf: string;
  invoiceNumber: string;
  hasViewClicked: boolean;
  isGeneratingInvoice: boolean;
  openInvoicePreview: boolean;
  clientProjecPermitList: ProjectPermitInvoiceType[],
  selectedInvoicesToPay: {[id:number]:boolean},
  totalStripeAmount: number,
  selectedInvoicesDataToPay: any[]
}

const initialState: FeesState = {
  inspectionInvoicesList: [],
  selectedProjectInvoice: {} as ProjectPermitInvoiceType,
  selectedFee: {} as PermitFeeItem,
  permitFees: [] as FeeItemType[],
  invoicePdf: '',
  invoiceNumber: '',
  isGeneratingInvoice: false,
  hasViewClicked: false,
  openInvoicePreview: false,
  clientProjecPermitList: [],
  invoiceHeader: {} as InvoiceHeader,
  selectedInvoicesToPay: {},
  totalStripeAmount: 0,
  selectedInvoicesDataToPay:[],
  invoiceBody: [
    {
      lineItems: [],
    },
  ],

};

export const feesSlice = createSlice({
  name: 'fees',
  initialState,
  reducers: {
    setPermitInvoicesList: (state, action: PayloadAction<PermitFeeItem[]>) => {
      state.inspectionInvoicesList = action.payload;
    },
    setSelectedProjectInvoice: (state, action: PayloadAction<ProjectPermitInvoiceType>) => {
      state.selectedProjectInvoice = action.payload;
      localStorage.setItem(SELECTED_PERMIT_ID,`${action.payload.permit.id}`);
    },
    setSelectedFee: (state, action: PayloadAction<PermitFeeItem>) => {
      state.selectedFee = action.payload;
    },
    resetFees: state => {
      state.inspectionInvoicesList = [];
      state.selectedFee = {} as PermitFeeItem;
    },
    setPermitFees: (state, action: PayloadAction<FeeItemType[]>) => {
      state.permitFees = action.payload;
    },
    setInvoiceHeader: (state, action: PayloadAction<InvoiceHeader>) => {
      state.invoiceHeader = action.payload;
    },
    setInvoiceBody: (state, action: PayloadAction<InvoicePage[]>) => {
      state.invoiceBody = action.payload;
    },
    setInvoicePDF : (state, action: PayloadAction<string>) => {
      state.invoicePdf = action.payload;
    },
    setInvoiceNumber : (state, action: PayloadAction<string>) => {
      state.invoiceNumber = action.payload;
    },
    setHasViewClicked : (state, action: PayloadAction<boolean>) => {
      state.hasViewClicked = action.payload;
    },
    setIsGeneratingInvoice : (state, action: PayloadAction<boolean>) => {
      state.isGeneratingInvoice = action.payload;
    },
    setOpenInvoicePreview: (state, action: PayloadAction<boolean>) => {
      state.openInvoicePreview = action.payload;
    },
    setClientProjecPermitList: (state, action: PayloadAction<ProjectPermitInvoiceType[]>) => {
      state.clientProjecPermitList = action.payload;
    },
    setSelectedInvoicesToPay: (state, action: PayloadAction<{[id:number]:boolean}>) => {
      state.selectedInvoicesToPay = action.payload;
    },
    setTotalStripeAmount : (state, action: PayloadAction<number>) => {
      state.totalStripeAmount = action.payload;
    },
    setSelectedInvoicesDataToPay: (state, action: PayloadAction<any[]>) => {
      state.selectedInvoicesDataToPay = action.payload;
      localStorage.setItem(INVOICES_TO_PAY_KEY,JSON.stringify(action.payload))
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setPermitInvoicesList,
  setSelectedProjectInvoice,
  setSelectedFee,
  resetFees,
  setPermitFees,
  setInvoiceBody,
  setInvoiceHeader,
  setInvoicePDF,
  setInvoiceNumber,
  setIsGeneratingInvoice,
  setHasViewClicked,
  setOpenInvoicePreview,
  setClientProjecPermitList,
  setSelectedInvoicesToPay,
  setTotalStripeAmount,
  setSelectedInvoicesDataToPay
} = feesSlice.actions;

export default feesSlice.reducer;
