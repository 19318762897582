import { MODE } from '../constants';

export default () => MODE === 'development';

export const getBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      cb(reader.result);
    };
    reader.onerror = error => {
      console.log('Error: ', error);
    };
  };