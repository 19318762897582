import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearFilter } from '../../redux/slices/filterSlice';
import { RootState } from '../../redux/root';
import { updateFilters } from '../../modules/clientModule/utils';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';
import { DebounceInput } from 'react-debounce-input';

const SearchInput = ({ name }) => {
    const dispatch = useDispatch();
    const [value, setValue] = useState<string>();
    const filters = useSelector((s: RootState) => s.filters.filters);

    useEffect(() => {
      if(name && filters){
        filters.forEach(f => {
          if(f.filterName === name){
            setValue(f.filterValue);
          }
        })
      }
    }, [])
    const removeFilterClick = (name,value) => {
      dispatch(clearFilter(name));
      setValue('');
    };
  
    const doFilter = (name,value) => {
      updateFilters(name, value, filters, dispatch);
      setValue(value);
    };
    return (
      <Search>
        <DebounceInput name={name} value={value} debounceTimeout={1000} onChange={e => doFilter(name,e.target.value)} />
        <IconButton>
          {value ? <CloseIcon onClick={e => removeFilterClick(name,value)} /> : <SearchIcon />}
        </IconButton>
      </Search>
    );
  
};

export default SearchInput;

const IconButton = styled.div``;

const Search = styled.div`
  display: flex;
  border: 1px solid #aaa;
  border-radius: 6px;
  min-height: 30px;
  background-color: var(--defaultBackground);
  padding: 2px 0px 2px 5px;
  input {
    outline: none;
    border: none;
    width: 100%;
  }
`;

