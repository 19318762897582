import * as React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import './api/interceptors';

import { ModalErrorRequest } from './molecules';
import { HOMEPAGE, ROUTES } from './constants/routes';
import PrivateRoute from './organisms/PrivateRoute';
import { isOpenErrorModalSelector } from './redux/ducks/requestApiError';
import useUserRole from './hooks/useUserRole';

import LogInPage from './pages/LogInPage';
import NotFoundPage from './pages/NotFoundPage';
import DashboardPage from './pages/DashboardPage';
import DashboardPageFA from './pages/DashboardPageFA';
import DashboardPageEmployee from './pages/DashboardPageEmployee';
import DashboardPageInspector from './pages/DashboardPageInspector';
import UserAccountPage from './pages/UserAccountPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ChangePasswordPage from './pages/ChangePasswordPage';
import ChangeEmailPage from './pages/ChangeEmailPage';
import SalesPage from './pages/Reports/SalesPage';
import TradesPage from './pages/Settings/TradesPage';
import MessagesPage from './pages/Settings/MessagesPage';
import FranchisePage from './pages/Franchise/FranchisePage';
import FranchiseConfigurationJurisdictionPage from './pages/Franchise/FranchiseConfigurationJurisdictionPage';
import FranchiseConfigurationUsersPage from './pages/Franchise/FranchiseConfigurationUsersPage';
import FranchiseConfigurationStripePage from './pages/Franchise/FranchiseConfigurationStripePage';
import ReportSetupPage from './pages/Settings/ReportSetupPage';
import FormSetupPage from './pages/Settings/FormSetupPage';
import SchedulingPage from './pages/Settings/SchedulingPage';
import JurisdictionPage from './pages/Settings/JurisdictionPage';
import InspectionTypesPage from './pages/Settings/InspectionTypesPage';
import FeesPage from './pages/Settings/FeesPage';
import ContactTypesPage from './pages/Settings/ContactTypesPage';
import ActivitiesPage from './pages/Settings/ActivitiesPage';
import VocabularyPage from './pages/Settings/VocabularyPage';
import DocumentsPage from './pages/Settings/DocumentsPage';
import UsersPage from './pages/Users/UsersPage';
import ProjectsPage from './pages/ProjectsPage';
import ContactsPage from './pages/ContactsPage';
import InvoicesPage from './pages/InvoicesPage';
import SearchPage from './pages/SearchPage';
import PermitPage from './pages/Permits/PermitPage';
import AllActivitiesPage from './pages/AllActivities/AllActivitiesPage';
import EmployeeReports from './pages/Reports/EmployeeReports';
import InspectorInspectionsPage from './pages/InspectorInspectionsPage/InspectorInspectionsPage';
import ReviewsPage from './pages/ReviewsPage';
import SearchPageInspector from "./pages/SearchPageInspector";
import ClientDashboard from './modules/clientModule/components/pages/Dashboard/Dashboard';
import ClientRoutes from './modules/clientModule/components/Routes/ClientRoutes';
import InspectorReports from './pages/Reports/InspectionReports/InspectorReports';
import useAppTimeout from './AppTimeout';
import ReferenceCodesPage from "./pages/Settings/ReferenceCodesPage";


const App = () => {
  const {
    isSuperAdmin,
    isFranchiseAdmin,
    isEmployee,
    isInspector,
    isClient,
  } = useUserRole();
  
  const {validateTimeout} = useAppTimeout();
  const errorModalIsOpen = useSelector(
    isOpenErrorModalSelector,
  );

  const resetPage = window.location.pathname === "/forgot-password";
  return (
    <div onMouseDown={e => validateTimeout('mouseDown')} onTouchStart={e => validateTimeout('touchStart')} onMouseMove={e => validateTimeout('mouseMove')} onScroll={e => validateTimeout('scroll')} onKeyDown={e => validateTimeout('keyDown')}>
     
      <Switch>       
        <Route path={ROUTES.LOG_IN} component={LogInPage} />

        <Route
          path={ROUTES.FORGOT_PASSWORD}
          component={ForgotPasswordPage}
        />

        <Route
          path={ROUTES.RESET_PASSWORD}
          component={ResetPasswordPage}
        />

        <PrivateRoute
          path={ROUTES.CHANGE_PASSWORD}
          component={ChangePasswordPage}
        />

        <PrivateRoute
          path={ROUTES.CHANGE_EMAIL}
          component={ChangeEmailPage}
        />

        <PrivateRoute
          path={ROUTES.DASHBOARD}
          component={
            (isSuperAdmin && DashboardPage) ||
            (isFranchiseAdmin && DashboardPageFA) ||
            (isEmployee && DashboardPageEmployee) ||
            (isInspector && DashboardPageInspector) || 
            (isClient && ClientDashboard)
          }
        />

        <PrivateRoute
          path={ROUTES.SETTINGS_JURISDICTION}
          component={JurisdictionPage}
        />

        <PrivateRoute
          path={ROUTES.SETTINGS_FEES}
          component={FeesPage}
        />

        <PrivateRoute
          path={ROUTES.SETTINGS_TRADES}
          component={TradesPage}
        />

        {isFranchiseAdmin && (
          <PrivateRoute
            path={ROUTES.SETTINGS_MESSAGES}
            component={MessagesPage}
          />
        )}

        <PrivateRoute
          path={ROUTES.SETTINGS_DOCUMENTS}
          component={DocumentsPage}
        />

        <PrivateRoute
          path={ROUTES.SETTINGS_SCHEDULING}
          component={SchedulingPage}
        />

        <PrivateRoute
          path={ROUTES.SETTINGS_INSPECTION_TYPE}
          component={InspectionTypesPage}
        />

        <PrivateRoute
          path={ROUTES.SETTINGS_REFERENCE_CODES}
          component={ReferenceCodesPage}
        />

        <PrivateRoute
          path={ROUTES.SETTINGS_REPORT_SETUP}
          component={isSuperAdmin && ReportSetupPage}
        />

        <PrivateRoute
          path={ROUTES.SETTINGS_FORM_SETUP}
          component={isSuperAdmin && FormSetupPage}
        />

        <PrivateRoute
          path={ROUTES.SETTINGS_CONTACT_TYPE}
          component={ContactTypesPage}
        />

        <PrivateRoute
          path={ROUTES.SETTINGS_REPORT_ACTIVITIES}
          component={ActivitiesPage}
        />

        {isSuperAdmin && (
          <PrivateRoute
            path={ROUTES.SETTINGS_VOCABULARY}
            component={VocabularyPage}
          />
        )}

        <PrivateRoute
          path={ROUTES.REPORTS_SALES}
          component={SalesPage}
        />

        {isEmployee && (
          <PrivateRoute
            path={ROUTES.REPORTS}
            component={EmployeeReports}
          />
        )}

        {isInspector && (
          <PrivateRoute
            path={ROUTES.REPORTS}
            component={InspectorReports}
          />
        )}
        <PrivateRoute
          exact
          path={ROUTES.FRANCHISE}
          component={FranchisePage}
        />

        <Redirect
          exact
          from={ROUTES.FRANCHISE_CONFIGURATION}
          to={ROUTES.FRANCHISE_CONFIGURATION_JURISDICTION}
        />

        <PrivateRoute
          path={ROUTES.FRANCHISE_CONFIGURATION_JURISDICTION}
          component={FranchiseConfigurationJurisdictionPage}
        />

        <PrivateRoute
          path={ROUTES.FRANCHISE_CONFIGURATION_USERS}
          component={FranchiseConfigurationUsersPage}
        />
        <PrivateRoute
          path={ROUTES.FRANCHISE_CONFIGURATION_STRIPE}
          component={FranchiseConfigurationStripePage}
        />

        <PrivateRoute
          path={ROUTES.USERS}
          component={UsersPage}
        />

        {isEmployee && (
          <PrivateRoute
            path={ROUTES.PROJECTS}
            component={ProjectsPage}
          />
        )}

        {isEmployee && (
          <PrivateRoute
            path={ROUTES.CONTACTS}
            component={ContactsPage}
          />
        )}

        {isEmployee && (
          <PrivateRoute
            path={ROUTES.PERMIT}
            component={PermitPage}
          />
        )}

        {isEmployee && (
          <PrivateRoute
            path={ROUTES.ALL_ACTIVITIES}
            component={AllActivitiesPage}
          />
        )}

        <PrivateRoute
          path={ROUTES.SEARCH}
          // component={
          //   isSuperAdmin || isEmployee
          //     ? SearchPage
          //     : SearchPageFA
          // }
          component={SearchPage}
        />

        <PrivateRoute
          path={ROUTES.INVOICES}
          component={InvoicesPage}
        />

        {isInspector && (
          <PrivateRoute
            path={ROUTES.INSPECTIONS}
            component={InspectorInspectionsPage}
          />
        )}

        {isInspector && (
          <PrivateRoute
            path={ROUTES.REVIEWS}
            component={ReviewsPage}
          />
        )}

        {isInspector && (
          <PrivateRoute
            path={ROUTES.SEARCH_INSPECTOR}
            component={SearchPageInspector}
          />
        )}

        <PrivateRoute
          path={ROUTES.INVOICES}
          component={NotFoundPage}
        />

        <PrivateRoute
          exact
          path={ROUTES.ACCOUNT}
          component={UserAccountPage}
        />

        <Redirect exact from="/" to={HOMEPAGE} />

        <Redirect
          exact
          from={ROUTES.SETTINGS}
          to={ROUTES.SETTINGS_JURISDICTION}
        />

        {!isEmployee && (
          <Redirect
            exact
            from={ROUTES.REPORTS}
            to={ROUTES.REPORTS_SALES}
          />
        )}
        <ClientRoutes />        
        <Route path="/" component={NotFoundPage} />
       
      </Switch>

      {!resetPage && errorModalIsOpen && <ModalErrorRequest />}
    </div>
  );
};

export default App;
