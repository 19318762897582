import { api } from './api';
import { PaginatedList, PaginatedRequest } from '../types';

export const getFranchiseList = (
  params: PaginatedRequest,
) =>
  api.request<FranchiseListType>({
    method: 'GET',
    url: '/franchises/',
    params,
  });

export const deleteFranchise = (id: number) =>
  api.request<FranchiseType>({
    method: 'DELETE',
    url: `/franchises/${id}/`,
  });

export const createFranchise = (data: FranchiseType) =>
  api.request<FranchiseType>({
    method: 'POST',
    url: '/franchises/',
    data,
  });

export const updateFranchise = (data: FranchiseType) =>
  api.request<FranchiseType>({
    method: 'PATCH',
    url: `/franchises/${data.id}/`,
    data,
  });

export const getFranchiseJurisdictionsList = (
  params: PaginatedRequest,
) =>
  api.request<FranchiseJurisdictionsTypeList>({
    method: 'GET',
    url: `/jurisdictions/complex-views/jurisdictions-by-franchise/`,
    params,
  });

export const updateFranchiseRelations = (
  data: number[],
  id,
) =>
  api.request<FranchiseType>({
    method: 'PATCH',
    url: `/franchises/relations/${id}/`,
    data,
  });
export const updateFranchiseStripe = (
  data,
  id,
) =>
  api.request<FranchiseType>({
    method: 'PUT',
    url: `/franchises/${id}/`,
    data,
  });

export const deleteFranchiseJurisdictions = (id: number) =>
  api.request<FranchiseType>({
    method: 'PUT',
    url: `/franchises/relations/${id}/clear-relations/`,
    data: { cleanupJurisdictions: true },
  });

export const getFranchiseUsersList = (
  params: PaginatedRequest,
) =>
  api.request<FranchiseUsersTypeList>({
    method: 'GET',
    url: `/account/complex-views/users-by-franchise/`,
    params,
  });
export const getFranchiseStripeList = (
  params: PaginatedRequest,
) =>
  api.request<FranchiseUsersTypeList>({
    method: 'GET',
    url: `/franchises/${params}/`,
    // params,
  });

export const deleteFranchiseUsers = (id: number) =>
  api.request<FranchiseType>({
    method: 'PUT',
    url: `/franchises/relations/${id}/clear-relations/`,
    data: { cleanupUsers: true },
  });

type FranchiseListType = PaginatedList<FranchiseType>;

export interface FranchiseType {
  id?: number;
  name: string;
  franchiseLevel?: string | FranchiseSelectType;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  contactPerson: string;
  address: string;
  isActive: boolean;
  isDeleted: boolean;
  franchiseType?: string | FranchiseSelectType;
  yearlyGoal: string;
  note: string;
  street: string;
  city: string;
  state: string;
  zipCode: string;
  toDate: string | Date;
  fromDate: string | Date;
  stripePublishableKey?: string;
}

export interface FranchiseFormType extends FranchiseType {
  franchiseType: FranchiseSelectType;
  franchiseLevel: FranchiseSelectType;
}

type FranchiseJurisdictionsTypeList = PaginatedList<
  FranchiseJurisdictionsType
>;

type FranchiseUsersTypeList = PaginatedList<
  FranchiseUsersType
>;
export interface FranchiseSelectType {
  value: string;
  label: string;
}

export interface FranchiseJurisdictionsType {
  id?: number;
  name: string;
  status: boolean;
}

export interface FranchiseUsersType {
  id?: number;
  firstName: string;
  lastName: string;
  status: boolean;
}
