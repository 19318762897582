/* eslint-disable no-nested-ternary */
import * as React from 'react';
import styled from 'styled-components';

import tableColumnsFormatter from '../../utils/tableColumnsFormatter';
import DashboardLayout from '../../layouts/DashboardLayout';
import {
  getReferenceCodes,
  createReferenceCodes,
  updateReferenceCodes,
  deleteReferenceCodes,
  ReferenceCodesTableType,
} from '../../api/referenceCodes';

import Table from '../../organisms/Table';
import ActionsPanel from '../../organisms/ActionsPanel';
import {
  ModalDefaultDraggable,
  ModalDeleteConfirmation,
} from '../../molecules';
import ModalInfo from '../../molecules/ModalInfo';
import useTablePage from '../../hooks/useTablePage';
import { convertTableBoolean } from '../../utils/convertTableValues';
import useUserRole from '../../hooks/useUserRole';
import { Loader } from '../../atoms';
import ReferenceCodesForm from "../../forms/ReferenceCodesForm";

const ReferenceCodesPage = () => {
  const {
    search,
    setSearch,
    modalError,
    modalSuccess,
    isModalOpen,
    selectedItem,
    setSelectedItem,
    modalTitle,
    values,
    errors,
    modalErrorTitle,
    tableManuallyUpdate,
    createOrUpdate,
    deleteById,
    handleAdd,
    handleEdit,
    handleDelete,
    handleCloseModal,
    handleCloseModalInfo,
  } = useTablePage<ReferenceCodesTableType>({
    createEndpoint: createReferenceCodes,
    updateEndpoint: updateReferenceCodes,
    deleteEndpoint: deleteReferenceCodes,
    modalCreateTitle: 'Add Reference Code',
    modalUpdateTitle: 'Update Reference Code',
  });
  const { isSuperAdmin, isFranchiseAdmin } = useUserRole();

 const [isLoading, setIsLoading] = React.useState(false);

const columnsOrder = [
    'name',
    'refCode',
    'Comments',
    'tradeName',
    'isActive',
  ];

  const columns = column => {
    if (
      [
        'id',
        'isDeleted',
        'tradeIds',
        isFranchiseAdmin ? 'isDefault' : null,
      ].includes(column)
    ) {
      return null;
    }

    return tableColumnsFormatter(column);
  };

  const handleCloseForm = () => {
    if (!modalError && !modalSuccess) {
      handleCloseModal();
    }
  };

  if (isLoading) {
    return (
      <DashboardLayout>
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <ActionsPanel
        onSearch={setSearch}
        onAdd={
          isSuperAdmin
            ? handleAdd
            : isFranchiseAdmin
            ? handleAdd
            : null
        }
      />
      <Container>
        <Table
          customSearch
          searchQuery={search}
          columns={columns}
          columnsOrder={columnsOrder}
          rows={getReferenceCodes}
          onEdit={
            isSuperAdmin
              ? handleEdit
              : isFranchiseAdmin
              ? handleEdit
              : null
          }
          onDelete={isSuperAdmin && handleDelete}
          formatValue={convertTableBoolean}
          manuallyUpdate={tableManuallyUpdate}
        />
      </Container>

      {isModalOpen && (
        <ModalDefaultDraggable
          onClose={handleCloseForm}
          title={modalTitle}
        >
          <ReferenceCodesForm
            initialValues={values}
            modalError={modalErrorTitle.length > 0}
            initialErrors={errors}
            onSubmit={createOrUpdate}
          />
        </ModalDefaultDraggable>
      )}

      {selectedItem && (
        <ModalDeleteConfirmation
          onCancel={() => setSelectedItem(null)}
          onConfirm={() => deleteById(selectedItem.id)}
        >
          <strong>{selectedItem.name}</strong>
        </ModalDeleteConfirmation>
      )}

      {(modalSuccess || modalError) && (
        <ModalInfo
          timeToClose={5000}
          isError={modalError}
          onClose={handleCloseModalInfo}
          errorMsg={modalErrorTitle}
        />
      )}
    </DashboardLayout>
  );
};

const Container = styled.div`
  margin: 24px 0 0 0;
`;

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default ReferenceCodesPage;
