import React, { FC } from 'react';
import styled from 'styled-components';
import logo from '../../resources/ep_circle.png';
import { InvoiceHeader } from './types';
import { Logo_Blue } from '../../constants/menu';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/root';

const LeftLogoArea:FC = () => {
  const header = useSelector((s:RootState) => s.fees.invoiceHeader);
  return (
    <Left>
      <ImageWrapper>
        <img src={logo} />
      </ImageWrapper>
      <ProjectArea>
        <InvoiceLabel>INVOICE</InvoiceLabel>
        <OneLine>
          <div>TO:</div>
          <FieldValue>{header.to}</FieldValue>
        </OneLine>

        <div>PROJECT ADDRESS:</div>
        <Address>
          <FieldValue>{header.projectAddress}</FieldValue>
        </Address>
        
      </ProjectArea>
    </Left>
  );
};

export default LeftLogoArea;

const ImageWrapper = styled.div`
 position: relative;
    top: 26px;
    left: 17px;
    width: 1.7in;
    height: 1.5in;
    border-radius: 50%;
    border: 2px solid ${Logo_Blue};
  img {
    width: 1.5in;
    position: relative;
    left: 0.1in;
  }
`;

const Address = styled.div`
 position: relative;
 top: -0.1in;
`;

const Left = styled.div`
  width: 50%;
  height: 100%;
  background-color: #fff;
`;

export const OneLine = styled.div`
  display: flex;
  gap: 12px;
`;

const ProjectArea = styled.div`
  margin: 34px;
  line-height: 28px;
  margin-right: 0;
  font-size: 12px;
`;

const InvoiceLabel = styled.div`
  font-size: 15px;
  color: #5442fa;
`;

export const FieldValue = styled.div`
  font-weight: bold;
`;
