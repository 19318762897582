import React, { FC, Fragment, useRef, forwardRef } from 'react';
import styled from 'styled-components';
import LeftLogoArea from './LeftLogoArea';
import RightSectionPart from './RightSection';
import { InvoiceHeader, InvoiceLineItem, InvoicePage } from './types';
import { currencyFormatCents } from '../../pages/Reports/DailyInspectionReport/Utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/root';

type Props = {
  lines: InvoiceLineItem[];
  isLastPage: boolean;
  totalPages: number;
  index: number;
  grandTotal: number
};

const InvoicePage = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { lines, isLastPage, totalPages, index, grandTotal } = props;
  const header = useSelector((s: RootState) => s.fees.invoiceHeader);

  return (
    <Wrapper ref={ref}>
      {header.paidDate && (
        <Paid>
          <div>Paid</div>
          <PaidDate>{header.paidDate}</PaidDate>
        </Paid>
      )}
      <Body>
        <Top>
          <LeftLogoArea />
          <RightSectionPart />
        </Top>
        <Center>
          <Table>
            <TableHeader>
              <div>DESCRIPTION</div>
              <div>AMOUNT</div>
              <div>QTY</div>
              <div>TOTAL</div>
            </TableHeader>
            <LineItem>
              {lines.map((line, index) => {
                return (
                  <Fragment key={index}>
                    <div>
                      <Desc>{line.name}</Desc>
                      <Note>{line.notes}</Note>
                    </div>
                    
                    <div>{currencyFormatCents(line.amount)}</div>
                    <div>{line.quantity}</div>
                    <div>{currencyFormatCents(line.quantity * line.amount)}</div>
                  </Fragment>
                );
              })}
            </LineItem>
            {isLastPage && (
              <>
                <BottomLine></BottomLine>
                <DueLine>
                  <Due>
                    <div>BALANCE DUE</div>
                    <div>{currencyFormatCents(grandTotal)}</div>
                  </Due>
                </DueLine>
              </>
            )}
          </Table>
        </Center>
      </Body>

      <Footer>
        {totalPages > 1 && (
          <Counter>
            Page {index + 1} of {totalPages}
          </Counter>
        )}
        <div>We appreciate your business and look forward to working with you!</div>
      </Footer>
    </Wrapper>
  );
});

export default InvoicePage;

const Paid = styled.div`
  position: absolute;
  border-top: 15px solid rgba(255, 0, 0, 0.3);
  border-bottom: 15px solid rgba(255, 0, 0, 0.3);
  width: 384px;
  height: 171px;
  font-size: 7em;
  color: rgba(255, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  transform: rotateZ(-25deg);
  top: 582px;
  left: 174px;
  flex-direction: column;
`;

const Note = styled.div`
  width: 100%;
  font-size: 16px;
  padding-left: 40px;
  padding-right: 45px;
  font-style: italic;
`;

const PaidDate = styled.div`
  font-size: 0.33in;
  position: relative;
  top: -0.2in;
`;

const Wrapper = styled.div`
  position: relative;
  width: 8.5in;
  height: 11in;
  display: flex;
  flex-direction: column;
`;

const Table = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.6in;
  margin: 0 30px;
  grid-gap: 13px;
  width: 90%;
`;

const TableHeader = styled.div`
  background-color: #cdddee;
  color: #60689f;
  display: grid;
  grid-template-columns: 1fr 0.8in 0.6in 0.6in;
  grid-column: 1 / 3;
  justify-content: space-between;
  padding: 15px 11px;
  font-weight: 600;
  grid-gap: 10px;
`;

const LineItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.8in 0.6in 0.6in;
  grid-column: 1 / 3;
  justify-content: space-between;
  padding: 15px 11px;
  grid-gap: 10px;
`;

const Desc = styled.div`
  padding-left: 20px;
`;

const BottomLine = styled.div`
  height: 2px;
  grid-column: 1/3;
  border-bottom: 2px dashed grey;
`;

const DueLine = styled.div`
  grid-column: 1/3;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const Due = styled.div`
  grid-column: 2 / 3;
  display: flex;
  justify-content: space-between;
  padding-bottom: 14px;
  border-bottom: 2px dashed grey;
`;

const Top = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 4.5in;
`;

const Center = styled.div``;
const Body = styled.div`
  flex: 1;
  background-color: #fff; //red;
`;

const Footer = styled.div`
  color: #aaa;
  padding: 10px 0;
  text-align: center;
  page-break-after: always;
  /* Ensure each page takes up the full height */
  height: 100vh;
  align-content: flex-end;
`;

const Counter = styled.div`
  text-align: left;
  color: grey;
  margin-left: 15px;
`;
